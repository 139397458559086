@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

@media (max-width: 767px) {
  iframe {
    max-width: 100%; } }

.swiper-slide {
  transition: 0.5s; }

.swiper-slide-active {
  transition: 0.5s; }

.swiper-slide-prev {
  transform: rotate(-35deg) translate(-610px);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0; }

.color-white {
  color: #ffffff; }

.color-light {
  color: #f7f7fc; }

.color-gray {
  color: #7c7c7c; }

.color-dark {
  color: #22323f; }

.color-black {
  color: #000000; }

.color-pink-light {
  color: #f09d9a; }

.color-pink-dark {
  color: #d17077; }

.color-light-blue {
  color: #dff0f4; }

.color-dark-red {
  color: #aa242d; }

.color-light-red {
  color: #c83e47; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-lightitalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-extrabolditalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-mediumitalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-heavyitalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-regularitalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-blackitalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-bolditalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-thinitalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-semibolditalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-ultralightitalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-ultralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  a:hover {
    text-decoration: none; }
    a:hover.hover-light {
      color: #f09d9a; }
    a:hover.hover-dark {
      color: #d17077; }

.h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 110%;
  letter-spacing: -0.03em; }
  .h1 sup {
    width: 17px;
    height: 19px;
    font-size: 20px;
    top: -1em; }

body.active {
  overflow: hidden; }

@media (max-width: 575px) {
  .h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 106.3%;
    /* or 36px */
    letter-spacing: 0.6px; }
    .h1 sup {
      font-size: 18px;
      top: -1em; } }

.h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120.49%; }

@media (max-width: 575px) {
  .h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px; } }

.h3 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 122.49%; }

.h4 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px; }

.b1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px; }

.b2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.69%; }

.b3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 154.69%; }

body {
  font-family: "Open Sans";
  max-width: 100vw; }
  body.hidden {
    overflow: hidden; }

#app {
  max-width: 100vw;
  overflow-x: hidden; }

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0; }

ul {
  padding: 0;
  margin: 0; }

button:focus,
input:focus,
textarea:focus {
  outline: none; }

strong {
  font-weight: 700;
  font-size: 25px;
  line-height: 30.62px; }

.scroll-styled::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 5px;
  border-bottom-right-radius: 5px; }

.scroll-styled::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #fff; }

.scroll-styled::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #aa242d; }

.container-default {
  width: 1180px;
  margin: 0 auto; }

@media (min-width: 992px) and (max-width: 1200px) {
  .container-default {
    width: 1024px; }
  .show#site-navigation::before {
    width: calc(50% - 60px); }
  .sidebar {
    width: 465px !important; }
  .sidebar__content {
    width: 410px !important; } }

@media (max-width: 992px) {
  .container-default {
    padding: 0 10px;
    width: 767px; } }

@media (max-width: 767px) {
  .container-default {
    width: 700px;
    padding: 0; } }

@media (max-width: 575px) {
  .container-default {
    width: 95%; } }

br.mobile {
  display: none; }

@media (max-width: 575px) {
  br.mobile {
    display: inline; } }

@media (max-width: 575px) {
  br.desktop {
    display: none; } }

#site-navigation {
  height: 96px;
  position: fixed;
  width: 100%;
  background: #ffffff;
  z-index: 50;
  top: 0;
  left: 0;
  transition: top 0.9s;
  border-bottom: 1px solid #b4b4b4; }
  #site-navigation:after {
    content: "";
    display: none;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    height: 1px;
    width: 100%;
    background: #b4b4b4; }
  #site-navigation .container-default {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%; }
  #site-navigation .dark {
    position: relative;
    z-index: 52;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 10px; }
    #site-navigation .dark img {
      margin-right: 36px;
      margin-left: -10px; }
  #site-navigation.show .menu-toggle {
    opacity: 0;
    visibility: hidden; }
  #site-navigation .white {
    padding-left: 47px;
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    #site-navigation .white .button-transparent {
      width: 309px;
      margin-right: 19px;
      margin-left: 19px; }
      #site-navigation .white .button-transparent:hover {
        background: #f09d9a;
        border-color: #f09d9a;
        color: #fff; }
    #site-navigation .white p {
      font-weight: 600; }
  #site-navigation::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% + 1px);
    background: #22323f;
    -webkit-transition: 1s 0.2s;
    -o-transition: 1s 0.2s;
    transition: 1s 0.2s;
    width: calc(50% - 350px);
    z-index: 51; }

.show#site-navigation::before {
  width: calc(50% - 80px);
  transform: scaleY(16);
  height: 100vh; }

@media (max-width: 1440px) {
  .show#site-navigation::before {
    transform: scaleY(16); } }

@media (max-width: 992px) {
  #site-navigation.appear::before {
    width: calc(50% - 130px); }
  .show#site-navigation.appear::before {
    width: calc(50% - -129px);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; } }

@media (max-width: 767px) {
  #site-navigation.appear::before {
    width: 50%; } }

@media (max-width: 370px) {
  #site-navigation.appear::before {
    width: 51%; } }

@media (min-width: 992px) and (max-width: 1200px) {
  #site-navigation::before {
    width: calc(50% - 280px); }
  #site-navigation .white p {
    display: none; } }

@media (max-width: 992px) {
  #site-navigation .white {
    padding-left: 2.3%; }
    #site-navigation .white p {
      display: none; }
    #site-navigation .white .button-transparent {
      width: 196px;
      margin-right: 18px;
      margin-left: 24px;
      font-size: 16px; }
    #site-navigation .white .button-pink {
      padding-left: 26px;
      padding-right: 26px;
      font-size: 16px; } }

@media (max-width: 767px) {
  #site-navigation {
    height: 72px;
    border: none; }
    #site-navigation::before {
      height: 100%; }
    #site-navigation .white {
      width: 50%;
      padding-left: 0; }
      #site-navigation .white p,
      #site-navigation .white .button-transparent {
        display: none; }
      #site-navigation .white .button-pink {
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 17px; }
        #site-navigation .white .button-pink span {
          display: none; }
    #site-navigation .dark {
      width: 50%;
      padding-right: 15px;
      padding-left: 15px;
      justify-content: space-between; }
      #site-navigation .dark img {
        margin-right: 11px;
        max-width: 80px;
        max-height: 80px; }
    #site-navigation .container-default {
      width: 100%; }
  .sidebar__content .district:nth-child(3),
  .sidebar__content .district:nth-child(5) {
    padding-left: 0 !important; } }

@media (max-width: 370px) {
  #site-navigation .dark {
    width: 50%;
    padding-left: 6px; }
  #site-navigation .white {
    width: 50%; }
    #site-navigation .white .button-pink {
      font-size: 16px; } }

.sidebar {
  width: 530px;
  visibility: hidden;
  position: absolute;
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  z-index: 100;
  padding-right: 64px;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s; }
  .sidebar.show {
    z-index: 52;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-transition: 0.7s 0.3s;
    -o-transition: 0.7s 0.3s;
    transition: 0.7s 0.3s; }
  .sidebar .close-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 55px;
    cursor: pointer; }
    .sidebar .close-sidebar:hover span {
      color: #f09d9a; }
    .sidebar .close-sidebar:hover svg path {
      stroke: #f09d9a; }
    .sidebar .close-sidebar span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #dff0f4;
      margin-right: 5px; }

@media (max-width: 992px) {
  .sidebar .close-sidebar {
    top: 39px; }
  .sidebar__content .sidebar__footer .left p {
    color: #dff0f4;
    opacity: 0.7; }
  .sidebar__content .sidebar__footer .social {
    margin-left: -9px; } }

.sidebar__header {
  border-bottom: 1.5px solid #415559;
  padding-top: 40px;
  padding-bottom: 50px;
  position: relative; }
  .sidebar__header a {
    color: #dff0f4;
    font-size: 24px;
    font-weight: 600;
    font-family: "Gilroy"; }
    .sidebar__header a:hover {
      color: #f09d9a; }
  .sidebar__header img {
    display: none; }

.sidebar__content {
  width: 464px;
  margin-left: -10px;
  position: absolute;
  top: 140px;
  height: 86vh;
  padding-top: 25px;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 10px;
  max-height: 500px; }
  .sidebar__content .district {
    width: 50%;
    margin-bottom: 19px; }
  .sidebar__content .district:nth-child(3),
  .sidebar__content .district:nth-child(5) {
    padding-left: 50px; }
  .sidebar__content .district__title {
    display: block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #dff0f4;
    margin-bottom: 16px; }
    .sidebar__content .district__title .mobile-toggler {
      display: none; }
  .sidebar__content .district a:hover {
    color: #f09d9a; }
  .sidebar__content .district ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .sidebar__content .district ul li {
      line-height: 22px;
      margin-bottom: 7px; }
      .sidebar__content .district ul li a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #dff0f4;
        opacity: 0.7; }
        .sidebar__content .district ul li a:hover {
          color: #f09d9a; }

@media (max-width: 992px) {
  .sidebar__header {
    padding-bottom: 25px; }
  .sidebar__content {
    top: 100px; } }

@media (min-width: 621px) {
  .d-block {
    display: none !important; } }

@media (max-width: 620px) {
  .homepage-achievements__title .h1 {
    margin-bottom: 24px !important; }
  .sidebar__content.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: initial;
    border-bottom: 1.5px solid transparent; }
  .sidebar__content::-webkit-scrollbar {
    width: 1px;
    border-bottom-right-radius: 5px;
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  .sidebar__content::-webkit-scrollbar-track {
    background: #575757; }
  .sidebar__content::-webkit-scrollbar-thumb {
    background: #f09d9a; }
  .sidebar__content .district {
    width: 100%;
    margin-bottom: 0; }
  .sidebar__content .district__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 15px;
    margin-bottom: 20px; }
    .sidebar__content .district__title .mobile-toggler {
      display: block;
      -webkit-transition: 1s;
      -o-transition: 1s;
      transition: 1s; }
    .sidebar__content .district__title.active .mobile-toggler {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  .sidebar__content .district ul {
    height: 0;
    overflow: hidden;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }
    .sidebar__content .district ul li {
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px); }
      .sidebar__content .district ul li:last-child {
        margin-bottom: 30px; }
    .sidebar__content .district ul.show li {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); } }

.sidebar__footer {
  padding-top: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #415559; }
  .sidebar__footer .left p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding-left: 3px;
    line-height: 19px;
    color: #dff0f4; }
  .sidebar__footer .right a {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #dff0f4;
    margin-bottom: 8px; }
    .sidebar__footer .right a:hover {
      color: #c83e47; }

@media (max-width: 767px) {
  .sidebar__footer .social {
    margin: 0 auto; }
  .sidebar__footer .left {
    width: 100%;
    padding-bottom: 10px; }
  .sidebar__footer .right {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; } }

@media (max-width: 767px) {
  .sidebar {
    width: 320px;
    padding-left: 18px;
    margin-left: 0 !important;
    padding-right: 0; }
  .sidebar__header {
    border-bottom: 1.5px solid transparent;
    padding-bottom: 45px; }
    .sidebar__header a {
      display: none; }
    .sidebar__header img {
      display: none; } }

@media (min-width: 992px) and (max-height: 756px) {
  .sidebar__header {
    padding-top: 40px;
    padding-bottom: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .sidebar__header img {
      width: 95px;
      height: 93px; }
    .sidebar__header .close-sidebar {
      position: unset; }
  .sidebar__content {
    top: 105px;
    height: 565px; } }

#shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  /*-webkit-backdrop-filter: blur(5px);*/
  /*backdrop-filter: blur(5px);*/
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }
  #shadow.show {
    pointer-events: all;
    opacity: 1; }

.navigation-offset {
  padding-top: 96px; }

@media (max-width: 767px) {
  .navigation-offset {
    padding-top: 72px; }
  .footer-bottom-default {
    width: 100%; }
    .footer-bottom-default a,
    .footer-bottom-default .made-by {
      margin-bottom: 10px; } }

footer#footer .footer-top {
  height: 483px;
  background: #f7f7fc;
  padding-top: 37px;
  padding-bottom: 70px; }

.footer-top .left img {
  width: 140px;
  height: 135px; }

footer#footer .footer-top .container-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

footer#footer .footer-top .left .contact-info {
  padding-left: 23px; }

footer#footer .footer-top .left .social {
  margin-top: 14px;
  padding-left: 19px; }

footer#footer .footer-top .right {
  padding-top: 93px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  footer#footer .footer-top .right .footer-menu-list {
    width: 25%; }

footer#footer .footer-bottom {
  height: 96px;
  background: #f09d9a; }
  footer#footer .footer-bottom .container-default {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    footer#footer .footer-bottom .container-default p.made-by {
      color: #000;
      font-size: 14px; }
  footer#footer .footer-bottom a {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 147.69%;
    color: #000000; }

footer#footer .footer-menu-list__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #22323f;
  margin-bottom: 32px; }
  footer#footer .footer-menu-list__title a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #22323f;
    margin-bottom: 20px;
    display: block; }
    footer#footer .footer-menu-list__title a:hover {
      color: #d17077; }

footer#footer .footer-menu-list ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  footer#footer .footer-menu-list ul li {
    margin-bottom: 7px; }
    footer#footer .footer-menu-list ul li a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #22323f;
      opacity: 0.6; }
      footer#footer .footer-menu-list ul li a:hover {
        color: #d17077; }

@media (max-width: 992px) {
  footer#footer .footer-top .right .footer-menu-list {
    width: unset;
    max-width: 50%; } }

#footer {
  position: relative;
  z-index: 1; }

@media (max-width: 992px) {
  footer#footer .footer-top {
    height: 100%; }
    footer#footer .footer-top .right {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding-top: 25px;
      margin-bottom: 33px; } }

@media (max-width: 620px) {
  footer#footer .footer-top .container-default {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  footer#footer .footer-top .left .contact-info,
  footer#footer .footer-top .left .social {
    display: none; } }

@media (max-width: 767px) {
  footer#footer .footer-bottom {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px; }
  footer#footer .footer-top .right .footer-menu-list {
    width: unset;
    min-width: 130px; }
  .sidebar .close-sidebar span {
    display: none; } }

@media (max-width: 767px) {
  footer#footer .footer-bottom .container-default {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px; }
    footer#footer .footer-bottom .container-default a {
      gap: 10px; } }

@media (max-width: 992px) {
  footer#footer .footer-menu-list {
    margin-bottom: 61px; }
  footer#footer .footer-menu-list__title {
    margin-bottom: 24px; } }

@media (min-width: 575px) {
  footer#footer .mobile-contact {
    display: none; }
  .footer-bottom-default {
    padding: 0 30px; } }

@media (max-width: 620px) {
  footer#footer .mobile-contact {
    border-top: 1px solid #cbcbcb;
    text-align: center;
    padding-top: 32px; }
  footer#footer .footer-top .left {
    padding-bottom: 33px;
    border-bottom: 1px solid #cbcbcb;
    text-align: center; }
  footer#footer .footer-menu-list:nth-child(3), footer#footer .footer-menu-list:nth-child(4) {
    margin-bottom: 24px; } }

.button-red {
  border-radius: 59px;
  border: 1px solid #aa242d;
  background: #aa242d;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 96%;
  text-transform: uppercase;
  color: #ffffff;
  padding: 24px 80px;
  height: 64px; }
  .button-red svg {
    margin-left: 14px; }
  .button-red:hover {
    border: 1px solid #c83e47;
    background: #c83e47;
    color: #ffffff; }

@media (max-width: 575px) {
  .button-red {
    padding: 24px 32px; } }

.button-pink {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #22323f;
  background: #f09d9a;
  border-radius: 35px;
  padding: 13px 45px; }
  .button-pink:hover {
    color: #22323f;
    background: #d17077; }

.button-transparent {
  display: inline-block;
  text-align: center;
  border-radius: 59px;
  padding: 13px 35px;
  border: 1px solid #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  overflow: hidden; }
  .button-transparent:hover {
    color: #ffffff; }
  .button-transparent.btn-dark {
    border: 1px solid #22323f;
    color: #22323f;
    background: #ffffff; }

.button-default {
  display: inline-block;
  padding: 11px 41px;
  font-family: "Open Sans";
  border-radius: 59px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #d17077;
  border: 2px solid #d17077;
  position: relative;
  overflow: hidden;
  background: transparent;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  cursor: pointer;
  z-index: 10; }
  .button-default .circle-pulse {
    background: #d17077; }
  .button-default .text {
    position: relative;
    z-index: 1; }
  .button-default.hover {
    color: #ffffff; }

.circle-pulse {
  position: absolute;
  border-radius: 100%;
  width: 0;
  height: 0;
  background: #d17077;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.button-next-slide,
.button-prev-slide {
  border: 2px solid #d17077;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 47px;
  width: 47px;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 10; }

.button-next-slide:hover svg,
.button-prev-slide:hover svg,
.button-next-slide.hover svg,
.button-prev-slide.hover svg {
  z-index: 2; }

.button-next-slide:hover svg path,
.button-prev-slide:hover svg path,
.button-next-slide.hover:not(.button-next-slide:disabled) svg path,
.button-prev-slide.hover:not(.button-prev-slide:disabled) svg path {
  stroke: #ffffff; }

.button-next-slide:disabled,
.button-prev-slide:disabled {
  opacity: 0.5;
  border: 2px solid #d17077;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 40px; }

.button-next-slide:disabled .circle-pulse,
.button-prev-slide:disabled .circle-pulse {
  display: none; }

.button-next-slide:disabled:hover svg path,
.button-prev-slide:disabled:hover svg path {
  stroke: #d17077 !important; }

.button-to-detail {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 2px solid #d17077;
  z-index: 10; }
  .button-to-detail:hover {
    background: #d17077; }
    .button-to-detail:hover svg path,
    .button-to-detail:hover svg line {
      stroke: #ffffff; }
  .button-to-detail svg path,
  .button-to-detail svg line {
    stroke: #d17077; }

.menu-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  visibility: visible;
  transition: all 0.7s; }
  .menu-toggle p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-right: 11px;
    color: #dff0f4;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px); }

@media (max-width: 340px) {
  .menu-toggle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .menu-toggle p {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      margin-top: 6px;
      margin-right: 0; } }

.filter-button {
  padding: 7px 41px;
  border: 1px solid #7c7c7c;
  border-radius: 35px;
  background: transparent;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  position: relative;
  height: 48px; }
  .filter-button span {
    padding-right: 5px; }
  .filter-button .circle-pulse {
    z-index: -1;
    background: #f7f7fc; }
  .filter-button.green span {
    color: #00602c; }
  .filter-button.red span {
    color: #d17077; }
  .filter-button:hover {
    border: 1px solid #f7f7fc; }

.dropdown-button {
  cursor: pointer;
  position: relative;
  display: inline-block; }
  .dropdown-button .dropdown-list-container {
    position: absolute;
    top: 100%;
    left: 0;
    background: #22323f;
    width: 100%;
    display: none; }
  .dropdown-button .dropdown-list-container__item {
    color: #ffffff; }
    .dropdown-button .dropdown-list-container__item:hover {
      background: #aa242d; }
  .dropdown-button .dropdown-list-container.show {
    display: block; }

.circle-button {
  z-index: 10;
  width: 56px;
  height: 56px;
  background: transparent;
  border: 1px solid #d17077;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden; }
  .circle-button:hover {
    cursor: pointer; }
    .circle-button:hover svg {
      z-index: 1; }
      .circle-button:hover svg path {
        fill: #ffffff; }

.number-box {
  padding-right: 15px;
  padding-left: 33px;
  border-right: 1px solid #f09d9a;
  height: 135px;
  position: relative;
  width: 33.3333%; }

.number-box__number {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
  color: #d17077;
  margin-bottom: 12px; }

.number-box__text {
  max-width: 196.67px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130.8%;
  color: #000000; }

.number-box:last-child {
  border-right: none; }

@media (min-width: 767) and (max-width: 992px) {
  .number-box {
    width: 50%;
    border-bottom: 1px solid #f09d9a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .number-box:last-child, .number-box:nth-child(3) {
      border-bottom: none; }
    .number-box:nth-child(2) {
      border-right: none; } }

@media (max-width: 767px) {
  .number-box {
    width: 100%;
    border: 1px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 24px;
    height: unset; }
  .number-box__number {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 51px;
    color: #d17077;
    width: 93px;
    margin-bottom: 0; }
  .number-box__text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130.8%;
    color: #000000;
    max-width: 163px;
    padding-left: 5px; }
  .number-box:last-child, .number-box:nth-child(3) {
    border-bottom: none; }
  .number-box:nth-child(2) {
    border-right: none; }
  .number-box::after {
    content: "";
    position: absolute;
    left: 24px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 48px);
    background: #f09d9a; }
  .number-box:last-child::after {
    display: none; } }

@media (max-width: 370px) {
  .number-box__number {
    width: 100px; }
  .number-box__text {
    max-width: calc(100% - 100px); } }

.achievement-box {
  border: 1px solid #bebebe;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 320px;
  width: 100%; }

@media (max-width: 992px) {
  .achievement-box {
    margin-left: 19px; } }

.achievement-box__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 16px;
  margin-top: 25px;
  max-width: 438px; }

.achievement-box__text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  max-width: 449px; }

@media (max-width: 767px) {
  .achievement-box {
    padding-bottom: 66px; }
  .achievement-box__title {
    font-size: 25px;
    line-height: 30px; }
  .achievement-box__text {
    font-size: 16px;
    line-height: 22px; } }

.court-case {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 36px;
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 24px;
  box-shadow: 2px 7px 23px rgba(0, 0, 0, 0);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  cursor: pointer; }

.court-case__status {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150.69%;
  color: #757575;
  margin-bottom: 32px; }
  .court-case__status .red {
    color: #d17077; }
  .court-case__status .green {
    color: #00602c; }

.court-case__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 122.49%;
  color: #000000;
  margin-bottom: 16px; }

.court-case__text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135.69%;
  color: #000000;
  max-width: initial; }

.court-case__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 24px; }

.court-case__tag {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #5f6368;
  margin-right: 10px; }

.court-case__date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150.69%;
  color: #7c7c7c;
  position: absolute;
  bottom: 20px; }

.court-case__to-detail {
  position: absolute;
  bottom: 24px;
  right: 32px;
  overflow: hidden; }
  .court-case__to-detail svg {
    z-index: 100; }
  .court-case__to-detail.button-to-detail {
    border: 2px solid #e9979e; }

.court-case::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #bebebe;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  pointer-events: none; }

@media (min-width: 992px) {
  .court-case:hover {
    background: #ffffff;
    -webkit-box-shadow: 2px 9px 30px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 9px 30px rgba(0, 0, 0, 0.4);
    border: none; }
    .court-case:hover:after {
      opacity: 0; }
  .footer-menu-list:nth-child(4) {
    padding-left: 30px; }
  .affairs-item.affair-small .affairs-item__desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
    height: 75px; } }

@media (max-width: 575px) {
  .court-case {
    padding-top: 32px;
    padding-bottom: 101px; }
  .court-case__status {
    margin-bottom: 24px; }
  .court-case__title {
    font-size: 25px;
    line-height: 122.49%; }
  .court-case__tag {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #5f6368; }
  .court-case__text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135.69%;
    color: #000000;
    margin-bottom: 24px;
    height: 110px;
    overflow: hidden; } }

.news-card {
  display: block; }

.news-card__image {
  height: 236px;
  background: #f7f7fc; }
  .news-card__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.news-card__content {
  padding-top: 21px;
  padding-left: 35px;
  padding-right: 32px;
  padding-bottom: 32px;
  position: relative;
  min-height: 277px; }
  .news-card__content::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #bebebe;
    pointer-events: none; }

.news-card__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 122.49%;
  color: #000000;
  margin-bottom: 16px; }

.news-card__desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135.69%;
  color: #000000;
  margin-bottom: 32px; }

.news-card__date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150.69%;
  color: #7c7c7c;
  position: absolute;
  left: 32px;
  bottom: 24px; }

.news-card__to-detail {
  position: absolute;
  font-family: "Roboto";
  color: #d17077;
  right: 32px;
  bottom: 24px; }
  .news-card__to-detail:hover {
    color: #c83e47; }

@media (max-width: 575px) {
  .news-card__content {
    padding-left: 24px;
    padding-top: 24px;
    padding-right: 24px;
    min-height: 233px; }
  .news-card__desc {
    display: none; }
  .news-card__to-detail {
    color: #d17077;
    right: 24px;
    bottom: 32px; }
  .news-card__date {
    bottom: 32px; } }

.feedback-box {
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
  background: #1e2d39;
  border: 1px solid #415559;
  border-right: none;
  min-height: 328px; }

.feedback-box__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 122.49%;
  color: #ffffff;
  margin-bottom: 16px; }

.feedback-box__text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147.69%;
  color: #ffffff;
  margin-bottom: 16px;
  min-height: 168px; }
  .feedback-box__text .hidden-word {
    display: none; }

.feedback-box__show-more {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #f09d9a;
  cursor: pointer; }
  .feedback-box__show-more:hover {
    color: #d17077; }
    .feedback-box__show-more:hover svg path {
      fill: #d17077; }

.feedback-box__link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #f09d9a; }
  .feedback-box__link:hover {
    color: #d17077; }

.feedback-box__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

@media (max-width: 575px) {
  .feedback-box {
    height: 460px; }
  .feedback-box__text {
    min-height: unset; }
  .feedback-box__link {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto; }
  .feedback-box__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset; } }

.affairs-item {
  height: 664px;
  margin-bottom: 16px;
  display: block;
  text-decoration: none;
  color: unset;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }
  .affairs-item:hover {
    text-decoration: none;
    color: unset; }
  .affairs-item.shadow-appear {
    opacity: 0; }

.affairs-item__image {
  height: 320px; }
  .affairs-item__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.affairs-item__content {
  padding-right: 32px;
  padding-left: 40px;
  padding-top: 32px;
  border: 1px solid #bebebe;
  border-top: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 344px;
  position: relative; }

.affairs-item__status {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.69%;
  margin-bottom: 16px;
  color: #757575; }
  .affairs-item__status.green span {
    color: #00602c; }
  .affairs-item__status.red span {
    color: #d17077; }

.affairs-item__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 114.49%;
  color: #000000;
  margin-bottom: 22px; }

.affairs-item__desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135.69%;
  color: #000000;
  margin-bottom: 16px; }

.affairs-item__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px; }

.affairs-item__tag {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.69%;
  color: #7c7c7c; }

.affairs-item__date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150.69%;
  color: #7c7c7c; }

.affairs-item__to-detail {
  position: absolute;
  bottom: 32px;
  right: 32px; }

.affairs-item.affair-large .affairs-item__title {
  max-width: 584px; }

.affairs-item.affair-large .affairs-item__desc {
  max-width: 590px; }

.affairs-item.affair-small .affairs-item__date {
  position: absolute;
  bottom: 32px; }

.affairs-item.appear {
  opacity: 1; }

@media (min-width: 767px) {
  .affairs-item.affair-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 506px; }
    .affairs-item.affair-flex .affairs-item__image {
      width: 50%;
      height: 100%; }
    .affairs-item.affair-flex .affairs-item__content {
      padding-left: 32px;
      width: 50%;
      height: 100%;
      border-top: 1px solid #bebebe;
      border-left: none; }
    .affairs-item.affair-flex .affairs-item__date {
      position: absolute;
      bottom: 32px; }
  .more-posts .carousel-wrapper .swiper-container {
    padding-left: 30px; } }

@media (min-width: 767px) {
  .affairs-item.affair-small .affairs-item__content {
    padding-top: 24px;
    padding-right: 26px;
    padding-left: 34px; } }

@media (min-width: 767px) {
  .affairs-item.affair-small .affairs-item__status {
    margin-bottom: 16px; } }

@media (min-width: 767px) {
  .affairs-item.affair-small .affairs-item__title {
    font-size: 20px;
    line-height: 122.49%;
    margin-bottom: 14px; } }

@media (min-width: 767px) {
  .affairs-item.affair-small .affairs-item__desc {
    font-size: 14px;
    line-height: 135.7%;
    margin-bottom: 16px; } }

@media (max-width: 767px) {
  .affairs-item {
    height: unset; }
  .affairs-item__content {
    height: unset;
    padding-bottom: 70px; } }

.comment-box {
  padding-top: 16px;
  padding-bottom: 25px; }

.comments-list__list > .comment-box {
  border-bottom: 1px solid #e8e8e8; }

.comment-box__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px; }

.comment-box__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 17px; }
  .comment-box__bottom .likes,
  .comment-box__bottom .dislikes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .comment-box__bottom .likes svg,
  .comment-box__bottom .dislikes svg {
    margin-right: 5px; }
  .comment-box__bottom .likes {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.18%;
    cursor: pointer;
    color: #00602c;
    margin-right: 20px; }
  .comment-box__bottom .dislikes {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.18%;
    color: #ea4335;
    margin-right: 20px;
    border: none; }
  .comment-box__bottom .reply {
    cursor: pointer;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.18%;
    color: #7c7c7c; }

.comment-box__date {
  margin-left: 16px;
  margin-top: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 147.69%;
  color: #7c7c7c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .comment-box__date svg {
    margin-right: 3px; }

.comment-box__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 122.49%;
  color: #22323f; }

.comment-box__content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #22323f; }
  .comment-box__content .hidden {
    display: none; }
  .comment-box__content.hide .hidden {
    display: inline; }

.comment-box__replies {
  padding-top: 20px;
  padding-left: 40px; }

.comment-box .show-more {
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #d17077;
  cursor: pointer; }
  .comment-box .show-more.hidden {
    display: none; }

.comment-box .reply-form {
  display: none;
  margin-top: 30px;
  -webkit-animation: drop 1s ease;
  animation: drop 1s ease; }
  .comment-box .reply-form.show {
    display: block; }

/*
  * SECTIONS
  */
.swiper-about {
  overflow: unset; }

#feedbacks {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 101px;
  background: #22323f; }
  #feedbacks .home-container h2 {
    font-family: "Montserrat";
    letter-spacing: 0.2px; }
  #feedbacks .home-container .button-default .text {
    font-family: "Roboto"; }
  #feedbacks .swiper-container {
    opacity: 0;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    padding-left: 50px; }
    #feedbacks .swiper-container.appear {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0); }
  #feedbacks .swiper-slide.hide {
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
    -webkit-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
    -ms-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
    transform: rotate(-30deg) translateX(-600px) translateY(-211px); }
    #feedbacks .swiper-slide.hide.show {
      -webkit-transition: 1s;
      -o-transition: 1s;
      transition: 1s;
      -webkit-transform: rotate(0) translateX(0) translateY(0) !important;
      -ms-transform: rotate(0) translateX(0) translateY(0) !important;
      transform: rotate(0) translateX(0) translateY(0) !important; }
  #feedbacks .more-info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 29px; }

#feedbacks .swiper-container {
  padding-left: 0; }

@media (min-width: 992px) {
  #feedbacks .swiper-container {
    padding-top: 54px; } }

@media (min-width: 992px) {
  #feedbacks .swiper-slide .feedback-box::after {
    border-right: none; }
  #feedbacks .swiper-slide:last-child .feedback-box::after {
    border-right: 1px solid #415559; }
  #feedbacks .swiper-container {
    padding-top: 54px; } }

.header-simple-page {
  height: 576px;
  position: relative; }
  .header-simple-page shadow {
    background: #22323f !important; }
  .header-simple-page .container-default {
    position: relative;
    height: inherit; }

.header-simple-page__background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1; }

.header-simple-page__title-box {
  position: absolute;
  padding-top: 80px;
  padding-left: 72px;
  width: 520px;
  height: 384px;
  background: #22323f;
  top: 97px;
  left: 0; }
  .header-simple-page__title-box .h1 {
    margin-bottom: 32px;
    max-width: 434px; }
  .header-simple-page__title-box .b1 {
    max-width: 552px; }

@media (max-width: 992px) {
  .header-simple-page__title-box {
    width: 630px;
    height: 400px;
    left: 0;
    top: unset;
    bottom: -200px; }
    .header-simple-page__title-box .b1 {
      max-width: 454px; } }

@media (max-width: 718px) {
  .header-simple-page__title-box {
    height: 464px;
    width: 100%;
    padding-left: 40px;
    padding-right: 30px; } }

.breadcrumbs {
  padding-top: 17px; }
  .breadcrumbs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none; }
    .breadcrumbs ul li a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff; }
    .breadcrumbs ul li:after {
      content: "/";
      color: #ffffff;
      margin-left: 5px;
      margin-right: 5px; }
    .breadcrumbs ul li:last-child:after {
      display: none; }

@media (max-width: 767px) {
  .breadcrumbs ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }

.comments-section {
  background: #f7f7fc;
  padding-top: 80px;
  padding-bottom: 110px; }

.comments-section__title {
  margin-bottom: 40px; }

.comments-section__form {
  margin-top: 40px; }

.comments-section .hidden-form {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .comments-section .hidden-form .name-container,
  .comments-section .hidden-form .email-container {
    width: 37%; }
  .comments-section .hidden-form .submit-container {
    width: 183px; }

@media (max-width: 767px) {
  .comments-section .hidden-form {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .comments-section .hidden-form .name-container,
    .comments-section .hidden-form .email-container {
      width: 100%;
      margin-top: 25px; }
    .comments-section .hidden-form .submit-container {
      margin-top: 25px;
      width: 100%; } }

@media (max-width: 575px) {
  .comments-section .hidden-form .h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 98.3%;
    color: #000000; } }

.comments-list__top {
  margin-top: 48px;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8; }

.swiper-scrollbar {
  margin-top: 33px;
  position: unset !important;
  background: #f7f7fc;
  height: 3px; }

.swiper-scrollbar-drag {
  height: 5px;
  background: #f09d9a; }

@media (min-width: 575px) {
  .swiper-scrollbar {
    display: none !important; } }

@-webkit-keyframes drop {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes drop {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

div.split {
  display: inline-block; }

.split span {
  overflow: hidden;
  position: relative;
  display: block; }
  .split span shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #1d2a35;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }

.split.appear span shadow {
  top: -100%; }

@media (max-width: 595px) {
  .split span {
    display: inline; }
    .split span shadow {
      display: none; } }

@-webkit-keyframes hide {
  0% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
    transform: rotate(-30deg) translateX(-600px) translateY(-211px); } }

@keyframes hide {
  0% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
    transform: rotate(-30deg) translateX(-600px) translateY(-211px); } }

.swiper-container .swiper-wrapper .swiper-slide {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }
  .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
    opacity: 0; }

.homepage-mass-media__carousel-buttons p {
  display: none; }

.input-default {
  border: 1px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 49px;
  width: 100%;
  height: 64px;
  position: relative; }
  .input-default .label {
    position: absolute;
    top: -10px;
    left: 50px;
    background: #22323f;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112%;
    color: #ffffff;
    padding-right: 10px;
    padding-left: 10px; }
  .input-default input {
    background: transparent;
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 50px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff; }
  .input-default.light {
    border: 1px solid #22323f; }
    .input-default.light .label {
      position: absolute;
      top: -10px;
      left: 50px;
      background: #f7f7fc;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 112%;
      color: #22323f;
      padding-right: 10px;
      padding-left: 10px; }
    .input-default.light input {
      background: transparent;
      width: 100%;
      height: 100%;
      border: none;
      padding-left: 50px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      color: #22323f; }

.input-comment {
  width: 100%;
  position: relative; }
  .input-comment .placeholder {
    pointer-events: none;
    position: absolute;
    top: 29%;
    left: 8%;
    background: #f7f7fc;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .input-comment input {
    border: 1.5px solid #d9d9d9;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 11px 21px;
    width: 100%;
    background: #f7f7fc;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150.18%; }
    .input-comment input::-webkit-input-placeholder, .input-comment input::-moz-placeholder, .input-comment input:-ms-input-placeholder, .input-comment input::-ms-input-placeholder, .input-comment input::placeholder {
      color: #5f6368; }
  .input-comment.active .placeholder {
    top: -24%; }

.checkbox-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer; }
  .checkbox-default .fake {
    width: 20px;
    height: 20px;
    border: 1px solid #d17077;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .checkbox-default .text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 112%;
    color: #ffffff;
    max-width: 300px;
    margin-left: 14px; }
  .checkbox-default input {
    display: none; }
  .checkbox-default.checked .fake {
    background: #d17077 url(../img/icons/shape.svg) no-repeat 50% 50%; }
  .checkbox-default.light .text {
    color: #22323f; }

@media (max-width: 420px) {
  .checkbox-default .text {
    font-size: 12px;
    margin-left: 10px;
    max-width: 219px; } }

.button-question {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 147.69%;
  color: #f7f7fc;
  padding: 13px 98px;
  border-radius: 44px;
  border: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  .button-question.red {
    background: #d17077; }
  .button-question.dark {
    background: #22323f; }

.button-dark {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.18%;
  color: #fbfbfb;
  background: #22323f;
  border-radius: 6px;
  padding: 11px 49px;
  width: 100%;
  border: 1px solid #22323f;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  .button-dark:hover {
    background: #d17077;
    border: 1px solid #d17077; }

.editor {
  border: 1.5px solid #d9d9d9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 172px;
  position: relative;
  cursor: pointer; }
  .editor .placeholder {
    position: absolute;
    left: 21px;
    top: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.18%;
    color: #7c7c7c;
    background: #f7f7fc;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .editor .editor-content {
    padding-left: 21px;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-right: 21px; }
    .editor .editor-content .bold {
      font-weight: bold; }
    .editor .editor-content .cursive {
      font-style: italic; }
    .editor .editor-content .blink {
      -webkit-animation: blink 1s infinite;
      animation: blink 1s infinite;
      color: #c83e47;
      display: none; }
  .editor .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 16px;
    bottom: 16px; }
    .editor .buttons .editor-button {
      margin-right: 16px; }
      .editor .buttons .editor-button:hover svg path,
      .editor .buttons .editor-button.active svg path {
        fill: #c83e47; }
  .editor.typing .placeholder {
    top: -15px; }
  .editor.typing .editor-content .blink {
    -webkit-animation: blink 1s infinite;
    animation: blink 1s infinite;
    color: #c83e47;
    display: inline; }

.comment-textarea {
  position: relative; }
  .comment-textarea textarea {
    border: 1.5px solid #d9d9d9;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    min-height: 172px;
    position: relative;
    cursor: pointer;
    padding-left: 21px;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-right: 21px; }
  .comment-textarea .set-images {
    position: absolute;
    right: 16px;
    bottom: 16px; }
    .comment-textarea .set-images:hover svg path,
    .comment-textarea .set-images.active svg path {
      fill: #c83e47; }

.img-input {
  margin-bottom: 0; }
  .img-input input {
    display: none; }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (min-width: 992px) {
  #homepage-body {
    padding-top: 0; }
    #homepage-body #site-navigation {
      display: none !important; } }

.home-container {
  width: 1020px;
  margin-right: auto;
  margin-left: 50px;
  position: relative; }

@media (max-width: 992px) {
  .home-container {
    margin-right: auto;
    margin-left: auto; } }

.home-row,
#homepage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.homepage-offset {
  width: 281px;
  z-index: 1000; }
  .homepage-offset .home-sidebar {
    width: 281px;
    background: #22323f;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0; }
  .homepage-offset .home-sidebar__header {
    padding-left: 70px;
    padding-top: 40px;
    padding-bottom: 73px; }
    .homepage-offset .home-sidebar__header img {
      width: 148px;
      height: 145.05px;
      margin-left: 16px; }
  .homepage-offset .home-sidebar__content {
    padding-left: 90px;
    position: absolute;
    width: 100%;
    top: 38%; }
    .homepage-offset .home-sidebar__content ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .homepage-offset .home-sidebar__content ul li {
        margin-bottom: 20px; }
        .homepage-offset .home-sidebar__content ul li a {
          font-family: Gilroy;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: #dff0f4;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-right: 12px;
          position: relative;
          transition: color 0.5s; }
          .homepage-offset .home-sidebar__content ul li a .chevron-icon {
            -webkit-transition: 0.5s;
            -o-transition: 0.5s;
            transition: 0.5s;
            position: absolute;
            right: 12px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            z-index: 100; }
            .homepage-offset .home-sidebar__content ul li a .chevron-icon svg {
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              transition: 0.5s; }
              .homepage-offset .home-sidebar__content ul li a .chevron-icon svg * {
                -webkit-transition: 0.5s;
                -o-transition: 0.5s;
                transition: 0.5s; }
          .homepage-offset .home-sidebar__content ul li a.active {
            color: #f09d9a; }
        .homepage-offset .home-sidebar__content ul li:hover a {
          color: #f09d9a;
          font-weight: 600; }
        .homepage-offset .home-sidebar__content ul li a.active .chevron-icon,
        .homepage-offset .home-sidebar__content ul li:hover a .chevron-icon {
          padding-top: 10px;
          padding-bottom: 10px; }
        .homepage-offset .home-sidebar__content ul li a.active .chevron-icon::after,
        .homepage-offset .home-sidebar__content ul li:hover a .chevron-icon::after {
          content: "";
          width: 100%;
          height: 100%;
          background: #22323f;
          -webkit-backdrop-filter: blur(33px);
          backdrop-filter: blur(33px);
          position: absolute;
          left: 0;
          top: 0; }
        .homepage-offset .home-sidebar__content ul li a.active .chevron-icon,
        .homepage-offset .home-sidebar__content ul li:hover a .chevron-icon {
          position: absolute;
          right: -14px;
          z-index: 100; }
        .homepage-offset .home-sidebar__content ul li a.active .chevron-icon svg,
        .homepage-offset .home-sidebar__content ul li:hover a .chevron-icon svg {
          z-index: 10;
          position: relative;
          height: 29px;
          width: 29px; }
        .homepage-offset .home-sidebar__content ul li a.active .chevron-icon svg path,
        .homepage-offset .home-sidebar__content ul li:hover a .chevron-icon svg path {
          stroke: #f09d9a; }
        .homepage-offset .home-sidebar__content ul li ul.sub-menu {
          padding-left: 0;
          position: fixed;
          left: 281px;
          top: 0;
          width: 0;
          min-width: 0;
          height: 100%;
          background: rgba(34, 50, 63, 0.91);
          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          transition: 0.5s;
          padding-top: 243px;
          z-index: -1;
          overflow: hidden; }
          .homepage-offset .home-sidebar__content ul li ul.sub-menu li {
            margin-bottom: 31px;
            opacity: 0;
            -webkit-transition: 0.5s;
            -o-transition: 0.5s;
            transition: 0.5s;
            -webkit-transition-delay: 0.5s;
            -o-transition-delay: 0.5s;
            transition-delay: 0.5s;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            min-width: 150px;
            padding-left: 90px; }
            .homepage-offset .home-sidebar__content ul li ul.sub-menu li a {
              font-family: Gilroy;
              font-style: normal;
              font-weight: normal;
              font-size: 35px;
              line-height: 41px;
              color: #dff0f4; }
              .homepage-offset .home-sidebar__content ul li ul.sub-menu li a:hover {
                color: #f09d9a; }
        .homepage-offset .home-sidebar__content ul li:hover ul.sub-menu {
          min-width: 550px;
          z-index: 80; }
          .homepage-offset .home-sidebar__content ul li:hover ul.sub-menu li {
            opacity: 1; }
  .homepage-offset .home-sidebar__footer {
    padding-left: 90px;
    position: absolute;
    bottom: 25px; }
    .homepage-offset .home-sidebar__footer a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #dff0f4; }
      .homepage-offset .home-sidebar__footer a:hover {
        color: #d17077; }
    .homepage-offset .home-sidebar__footer .social {
      margin-left: -5px;
      width: 170px; }
      .homepage-offset .home-sidebar__footer .social a {
        margin-left: -6px; }
  .homepage-offset .home-sidebar::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.5px;
    height: 100%;
    background: #415559; }

@media (max-width: 992px) {
  .homepage-offset .home-sidebar {
    display: none; } }

#home-content {
  width: calc(100% - 281px);
  min-height: 100vh; }

@media (max-width: 992px) {
  #home-content {
    width: 100%; } }

.homepage-header {
  width: 100%;
  height: 100vh;
  background-position-x: 100%;
  background-position-y: 50%;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  position: relative;
  z-index: -11; }
  .homepage-header.z-index {
    z-index: 0;
    position: fixed;
    background: #1d2a35 url(../img/bg/home-tiger.png) no-repeat;
    background-position: 74% 0; }

.homepage-header__top {
  padding-top: 35px;
  padding-bottom: 71px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .homepage-header__top .button-transparent {
    font-weight: 400; }
  .homepage-header__top .ur-help {
    margin-left: 22px; }

@media (max-width: 500px) {
  .homepage-header__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .homepage-header__top .ur-help {
      max-width: 40%; } }

@media (max-width: 400px) {
  .homepage-header__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .homepage-header__top .ur-help {
      max-width: 30%;
      font-size: 12px; } }

.homepage-header__title {
  max-width: 585px;
  margin-bottom: 40px; }

.homepage-header__subtitle {
  font-size: 16px;
  line-height: 25px;
  max-width: 538px;
  margin-bottom: 48px;
  font-weight: 400;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s; }

.homepage-header .button-red {
  width: 454px; }

.homepage-header__numbers {
  position: absolute;
  top: 84%;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  padding: 24px;
  background: #ffffff;
  border: 1.5px solid rgba(240, 157, 154, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }

.homepage-header__top .button-transparent:hover {
  background: #fff;
  color: #22323f; }

@media (max-width: 992px) {
  .homepage-header__numbers {
    position: absolute;
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 700px; } }

@media (max-width: 767px) {
  .f-c {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .homepage-header__numbers {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    bottom: unset;
    top: 100%;
    left: 50%;
    width: calc(100% - 30px);
    z-index: 10; } }

.homepage-header.short {
  height: 572px; }
  .homepage-header.short .homepage-header__numbers {
    opacity: 1; }
  .homepage-header.short .button-red {
    opacity: 0; }

@media (max-width: 575px) {
  .homepage-header.z-index {
    height: 848px;
    background: #1d2a35 url(../img/bg/home-tiger-m.png) no-repeat;
    background-position-x: 100%; }
  .homepage-header__top {
    padding-bottom: 76px;
    padding-top: 32px; }
    .homepage-header__top .button-transparent {
      border: 1px solid #ffffff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 34px;
      padding: 11px 17px;
      width: 192px;
      height: 44px; }
    .homepage-header__top .ur-help {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 109.18%;
      color: #ffffff;
      margin-left: 14px;
      max-width: 142px; }
  .homepage-header__title {
    max-width: 320px;
    margin-bottom: 24px; }
  .homepage-header__subtitle {
    max-width: 340px;
    margin-bottom: 40px; }
  .homepage-header .button-red {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 96%;
    text-transform: uppercase;
    color: #ffffff;
    background: #aa242d;
    border-radius: 59px;
    width: 340px;
    height: 64px; }
  .homepage-header.short {
    height: 848px; }
  .homepage-header__numbers {
    z-index: 10;
    top: 111%;
    width: 94% !important; }
    .homepage-header__numbers::after {
      background: rgba(27, 27, 27, 0.22);
      -webkit-filter: blur(40px);
      filter: blur(40px);
      width: 99%;
      left: 0.5%;
      bottom: -18px;
      height: 27px;
      -webkit-transform: translateZ(-1px);
      transform: translateZ(-1px); }
  .homepage-header.short .button-red {
    opacity: 1; } }

.homepage-quote {
  min-height: 579px;
  background: #f7f7fc;
  position: relative; }
  .homepage-quote shadow {
    background: #f7f7fc !important; }

.homepage-quote__title {
  position: absolute;
  top: 184px;
  left: 0;
  max-width: 490px;
  overflow: hidden; }
  .homepage-quote__title > * {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    color: #d17077; }

@media (min-width: 370px) {
  .anim-text-title > * {
    line-height: 204.4%;
    transition: all 1.4s;
    transform: translate(0, 150px); }
  .anim-text-title.appear > * {
    line-height: 104.4%;
    transform: translate(0, 0); }
  .anim-text > * {
    line-height: 40px;
    transition: all 1.4s;
    transform: translate(0, 150px); }
  .anim-text.appear > * {
    line-height: 24px;
    transform: translate(0, 0); } }

.homepage-quote__quote {
  position: absolute;
  top: 270px;
  right: 4%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #22323f;
  width: 450px;
  z-index: 2; }

.homepage-quote__collibri {
  position: absolute;
  width: 235px;
  top: 274px;
  left: 236px;
  opacity: 0;
  -webkit-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
  transition-delay: 2.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  .homepage-quote__collibri.appear {
    opacity: 1; }

@media (max-width: 992px) {
  .homepage-quote {
    min-height: 750px; }
  .homepage-quote__title {
    top: 203px; }
  .homepage-quote__quote {
    top: 462px; }
  .homepage-quote__collibri {
    width: 371px;
    top: 118px;
    left: 198px; } }

@media (max-width: 767px) {
  .homepage-quote {
    min-height: 1032px; }
  .homepage-quote__title {
    position: unset;
    padding-top: 300px;
    font-size: 34px;
    line-height: 104.4%;
    margin-bottom: 32px; }
  .homepage-quote__quote {
    position: unset;
    font-size: 16px;
    line-height: 22px; }
  .homepage-quote__collibri {
    max-width: 100%;
    top: 341px;
    left: 152px; } }

@media (max-width: 425px) {
  .home-container {
    padding: 0 10px;
    width: 100%; } }

@media (max-width: 375px) {
  .homepage-quote__collibri {
    width: 348px;
    top: 457px;
    left: 0; }
  .homepage-quote__title {
    padding-top: 350px; }
    .homepage-quote__title > * {
      font-size: 34px; } }

.homepage-achievements {
  position: relative;
  z-index: 1;
  background: #fff;
  padding-top: 80px;
  padding-bottom: 120px; }
  .homepage-achievements shadow {
    background: #ffffff !important; }

.homepage-achievements__title {
  width: 50%; }
  .homepage-achievements__title .anim-text {
    width: 387px; }
  .homepage-achievements__title .h1 {
    margin-bottom: 32px; }
  .homepage-achievements__title.anime-block {
    height: 256px; }

.f-c.anime-block {
  height: 172px; }

.homepage-achievements .achievement-box {
  width: 50%;
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }

.homepage-achievements.appear .achievement-box {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
  opacity: 1;
  -webkit-transform: rotate(0) translateX(0) translateY(0);
  -ms-transform: rotate(0) translateX(0) translateY(0);
  transform: rotate(0) translateX(0) translateY(0); }

@media (min-width: 992px) {
  .homepage-achievements .achievement-box.box-1 {
    border-bottom: none; }
  .homepage-achievements .achievement-box.box-2 {
    border-right: none;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s; }
  .homepage-achievements .achievement-box.box-3 {
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s; }
  .homepage-achievements .achievement-box.box-4 {
    border-top: none;
    border-right: none;
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s; }
  .homepage-achievements .achievement-box.last {
    border-top: none;
    -webkit-transition-delay: 0.9s;
    -o-transition-delay: 0.9s;
    transition-delay: 0.9s; } }

@media (max-width: 992px) {
  .homepage-achievements__title {
    width: 100%;
    margin-bottom: 15px;
    margin-right: calc(50% - 155px); }
  .homepage-achievements .achievement-box {
    width: 511px;
    border-bottom: none; }
    .homepage-achievements .achievement-box.last {
      border-bottom: 1px solid #bebebe; } }

.homepage-jury-trial {
  position: relative;
  z-index: 1;
  padding-bottom: 110px;
  background: #fff; }
  .homepage-jury-trial .f-c {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px; }
  .homepage-jury-trial shadow {
    background: #ffffff !important; }

.homepage-jury-trial__title {
  display: flex;
  height: 100%;
  flex-direction: column; }
  .homepage-jury-trial__title .h1 svg {
    margin-left: 30px; }
  .homepage-jury-trial__title .b2 {
    max-width: 400px;
    margin-top: 24px; }

.homepage-jury-trial__carousel-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
  .homepage-jury-trial__carousel-buttons p {
    margin-right: 20px; }
  .homepage-jury-trial__carousel-buttons .button-prev-slide {
    margin-right: 8px; }

@media (max-width: 767px) {
  .homepage-jury-trial__carousel-buttons {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 30px; }
    .homepage-jury-trial__carousel-buttons .button-prev-slide,
    .homepage-jury-trial__carousel-buttons .button-next-slide {
      display: none; } }

.homepage-jury-trial__more-info {
  padding-top: 27px;
  text-align: center; }

.homepage-jury-trial .carousel-wrapper {
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }
  .homepage-jury-trial .carousel-wrapper.appear {
    opacity: 1; }
  .homepage-jury-trial .carousel-wrapper .swiper-container {
    overflow: unset;
    padding-left: 50px; }

@media (max-width: 992px) {
  .homepage-jury-trial .carousel-wrapper .swiper-container {
    padding-left: 15px; } }

@media (min-width: 992px) {
  .homepage-jury-trial .swiper-slide .court-case::after {
    border-right: none; }
  .homepage-jury-trial .swiper-slide:last-child .court-case::after {
    border-right: 1px solid #bebebe; } }

@media (max-width: 575px) {
  .homepage-jury-trial .swiper-slide:nth-child(2n + 2) .court-case::after {
    border-right: none;
    border-left: none; }
  .homepage-jury-trial .swiper-slide:last-child .court-case::after {
    border-right: 1px solid #bebebe; } }

.homepage-mass-media {
  position: relative;
  z-index: 1;
  padding-top: 72px;
  padding-bottom: 110px;
  background: #fff; }
  .homepage-mass-media .home-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 46px;
    align-items: center; }
  .homepage-mass-media shadow {
    background: #ffffff !important; }

.homepage-mass-media__carousel-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .homepage-mass-media__carousel-buttons p {
    margin-right: 20px; }
  .homepage-mass-media__carousel-buttons .button-prev-slide {
    margin-right: 8px; }

.homepage-mass-media__carousel {
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }
  .homepage-mass-media__carousel.appear {
    opacity: 1; }

@media (max-width: 992px) {
  .homepage-mass-media__carousel {
    padding-left: 0px;
    margin: 0 auto; } }

.quote-section {
  padding-bottom: 118px; }
  .quote-section shadow {
    background: #ffffff !important; }
  .quote-section .container-default {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

@media (min-width: 993px) {
  .quote-section {
    padding-top: 65px; } }

.quote-section__quote {
  max-width: 666px;
  margin-bottom: 24px;
  font-family: Gilroy;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  line-height: 119.9%;
  text-align: center;
  color: #22323f; }
  .quote-section__quote span {
    display: block !important; }

.affairs-list__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 32px;
  margin-bottom: 24px;
  border-bottom: 1px solid transparent;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  position: relative; }
  .affairs-list__filter .filter-button {
    margin-right: 16px; }
  .affairs-list__filter .dropdown-button {
    margin-left: auto;
    margin-bottom: 7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.69%;
    color: #000000; }
  .affairs-list__filter .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .affairs-list__filter .right .dropdown-button .trigger .output {
    color: #000; }
  .affairs-list__filter .right .dropdown-button .trigger svg path {
    stroke: #000; }
  .affairs-list__filter::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }
  .affairs-list__filter.appear {
    border-bottom: 1px solid #7c7c7c; }
    .affairs-list__filter.appear::after {
      height: 0; }

@media (min-width: 1000px) and (max-width: 1390px) {
  .home-container {
    width: 950px; }
  .donate-block .home-container {
    margin-left: 10px; }
    .donate-block .home-container .donate-block__calculator-container .donate-block__form {
      width: 650px; }
      .donate-block .home-container .donate-block__calculator-container .donate-block__form .donate-block__form__bottom .donate-button {
        font-size: 16px; }
    .donate-block .home-container .donate-block__calculator-container .donate-block__regular {
      width: 300px; }
      .donate-block .home-container .donate-block__calculator-container .donate-block__regular .donate-block__regular__button {
        width: 100%;
        font-size: 16px; } }

@media (max-width: 992px) {
  .affairs-list__filter {
    display: block;
    padding-bottom: 6px; }
    .affairs-list__filter .left {
      margin-bottom: 29px; } }

@media (max-width: 767px) {
  .affairs-list__filter {
    display: block; }
    .affairs-list__filter .left {
      display: block;
      margin-bottom: 17px; }
    .affairs-list__filter .filter-button {
      width: 48%;
      margin-right: 0;
      font-size: 14px;
      padding: 11px 20px; }
      .affairs-list__filter .filter-button .mobile-hidden {
        display: none; } }

.affairs-list__count {
  margin-right: 40px; }

@media (max-width: 992px) {
  .affairs-list__count {
    font-size: 30px;
    line-height: 120.49%; } }

@media (max-width: 767px) {
  .affairs-list__count {
    margin-bottom: 30px; } }

.affairs-list .text-center {
  margin-bottom: 124px !important; }
  .affairs-list .text-center a {
    text-align: center;
    width: 248px;
    font-weight: 600; }

.affairs-list__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px; }
  .affairs-list__list .active-affairs {
    margin-right: 20px;
    width: 780px; }
  .affairs-list__list .not-active-affairs {
    width: 380px; }

@media (max-width: 992px) {
  .affairs-list__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .affairs-list__list .active-affairs {
      width: 100%;
      margin-right: 0; }
    .affairs-list__list .not-active-affairs {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .affairs-list__list .not-active-affairs .affairs-item {
        width: calc(50% - 10px); } }

@media (max-width: 767px) {
  .affairs-list__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .affairs-list__list .active-affairs {
      width: 100%;
      margin-right: 0; }
    .affairs-list__list .not-active-affairs {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .affairs-list__list .not-active-affairs .affairs-item {
        width: 100%; } }

.affairs-list__show-more {
  text-align: center;
  padding-top: 44px;
  padding-bottom: 124px; }

.detail-page .breadcrumbs ul li a {
  color: #22323f; }

.detail-page .breadcrumbs ul li:after {
  content: "/";
  color: #22323f;
  margin-left: 5px;
  margin-right: 5px; }

.detail-page .breadcrumbs ul li:last-child a {
  color: #7c7c7c; }

.detail-page .breadcrumbs ul li:last-child:after {
  display: none; }

.detail-page__title {
  margin-top: 40px;
  margin-bottom: 40px; }
  .detail-page__title shadow {
    background: #ffffff !important; }
  .detail-page__title .h1 {
    max-width: 982px; }

.detail-page__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 11px;
  margin-bottom: 32px;
  position: relative; }
  .detail-page__info .updated-at {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 147.69%;
    color: #7c7c7c;
    margin-right: 40px; }
  .detail-page__info .status {
    margin-right: 40px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150.69%;
    color: #7c7c7c; }
    .detail-page__info .status.green span {
      color: #00602c; }
    .detail-page__info .status.red span {
      color: #d17077; }
  .detail-page__info .views {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 147.69%;
    color: #7c7c7c;
    margin-right: 22px; }
  .detail-page__info .comments {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 147.69%;
    color: #000000; }
  .detail-page__info .share {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .detail-page__info .share p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 147.69%;
      color: #7c7c7c; }
  .detail-page__info:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    height: 100%;
    width: 100%;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }
  .detail-page__info.appear:after {
    height: 0; }
  .detail-page__info.top {
    border-bottom: 1px solid #8d8d8d; }
  .detail-page__info.bottom {
    padding-top: 20px;
    border-top: 1px solid #8d8d8d; }

.detail-page__image {
  margin-bottom: 45px; }
  .detail-page__image img {
    width: 100%; }

.detail-page__content .text-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .detail-page__content .text-content h3 {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 122.49%;
    margin-bottom: 16px;
    width: 100%; }
  .detail-page__content .text-content p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    max-width: 780px;
    margin-bottom: 20px; }
  .detail-page__content .text-content img {
    margin-bottom: 20px;
    max-width: 100%; }

@media (max-width: 992px) {
  .detail-page__title .h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 120.49%;
    color: #000000;
    max-width: 700px; }
  .detail-page__info .updated-at {
    width: 50%;
    margin-right: 0; }
  .detail-page__info .status {
    width: 50%;
    margin-right: 0;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    margin-top: 15px; }
  .detail-page__info .views {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-left: auto; }
  .detail-page__info .comments {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .detail-page__info .share {
    margin-top: 15px;
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .detail-page__info.top {
    border-bottom: 1px solid #8d8d8d; }
  .detail-page__info.bottom {
    padding-top: 20px;
    border-top: 1px solid #8d8d8d; } }

@media (max-width: 450px) {
  .detail-page__info .updated-at {
    width: 100%;
    margin-right: 0; }
  .detail-page__info .status {
    width: 100%;
    margin-right: 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 15px; }
  .detail-page__info .views {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    margin-left: auto; }
  .detail-page__info .comments {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .detail-page__info .share {
    margin-left: 0;
    margin-top: 15px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
    .detail-page__info .share p {
      display: none; }
  .detail-page__info.top {
    border-bottom: 1px solid #8d8d8d; }
  .detail-page__info.bottom {
    padding-top: 20px;
    border-top: 1px solid #8d8d8d; } }

@media (max-width: 575px) {
  .detail-page .share-block-container {
    display: none; } }

.detail-page .share-block-container__title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 147.69%;
  color: #7c7c7c;
  margin-bottom: 10px; }

.detail-page .share-block-container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.detail-page .share-block-container .views {
  margin-top: 50px;
  margin-bottom: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 147.69%;
  color: #7c7c7c; }

.detail-page .share-block-container .comments {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 147.69%;
  color: #000000; }

.vote-section {
  position: relative;
  background: url(../img/bird-light.png) no-repeat;
  background-position-y: 414px;
  background-position-x: calc(44% - 117px);
  height: 702px; }
  .vote-section .left {
    padding-top: 75px; }
    .vote-section .left shadow {
      background: #22323f !important; }
    .vote-section .left h3 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 101.99%;
      max-width: 459px; }
  .vote-section .right {
    padding-top: 74px;
    padding-left: 62px;
    display: none; }
    .vote-section .right shadow {
      background: #dff0f4 !important; }
    .vote-section .right.show {
      display: block; }
  .vote-section .vote-choise .b1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 135.18%; }
  .vote-section .vote-choise .vote-circle {
    margin-left: 200px;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 255px;
    height: 255px;
    border: 8px solid #22323f;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .vote-section .vote-choise .vote-circle p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 127.3%;
      text-align: center;
      color: #22323f; }
  .vote-section .vote-choise .q {
    margin-left: 275px; }
  .vote-section .vote-choise .answer-box {
    margin-top: 24px;
    margin-left: 100px; }
    .vote-section .vote-choise .answer-box.type-2 {
      margin-top: 24px;
      margin-left: 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .vote-section .vote-choise .answer-box.type-2 .button-question {
        font-size: 16px; }
    .vote-section .vote-choise .answer-box .button-question:first-child {
      margin-right: 16px; }
  .vote-section .start-soon .h1 {
    margin-top: 105px;
    max-width: 422px; }
  .vote-section .show-persents .graph-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    padding-bottom: 32px;
    padding-top: 32px; }
    .vote-section .show-persents .graph-container .persents {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 80%;
      text-align: center;
      color: #22323f;
      width: 200px;
      max-width: 100%; }
      .vote-section .show-persents .graph-container .persents span {
        font-size: 24px; }
  .vote-section .show-persents .q {
    text-align: center;
    margin-bottom: 32px; }
  .vote-section .show-persents .answer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .vote-section .show-persents .answer-box .circle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .vote-section .show-persents .answer-box .circle::before {
        content: "";
        width: 31px;
        height: 31px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 16px; }
      .vote-section .show-persents .answer-box .circle.red::before {
        background: #d17077; }
      .vote-section .show-persents .answer-box .circle.dark::before {
        background: #22323f; }
      .vote-section .show-persents .answer-box .circle:first-child {
        margin-right: 50px; }
  .vote-section::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 44.5%;
    background: #22323f;
    z-index: -1; }
  .vote-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 55.5%;
    background: #dff0f4;
    z-index: -1; }

@media (max-width: 992px) {
  .vote-section {
    height: unset;
    background-position-y: 227px;
    background-position-x: 80%; }
    .vote-section::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 472px;
      width: 100%;
      background: #22323f;
      z-index: -1; }
    .vote-section .left {
      height: 472px; }
      .vote-section .left h3 {
        max-width: 457px; }
    .vote-section::after {
      content: "";
      position: absolute;
      right: 0;
      top: 472px;
      height: 686px;
      width: 100%;
      background: #dff0f4;
      z-index: -1; }
    .vote-section .right {
      padding-top: 30px;
      padding-bottom: 80px;
      padding-left: 0px; } }

@media (max-width: 450px) {
  .vote-section {
    background-position-y: 250px; }
    .vote-section::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 508px;
      width: 100%;
      background: #22323f;
      z-index: -1; }
    .vote-section .left {
      height: 508px; }
      .vote-section .left h3 {
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 101.99%; }
    .vote-section::after {
      content: "";
      position: absolute;
      right: 0;
      top: 508px;
      height: 100%;
      width: 100%;
      background: #dff0f4;
      z-index: -1; }
    .vote-section .show-persents .answer-box {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-left: 30px; }
      .vote-section .show-persents .answer-box .circle {
        font-weight: bold; }
        .vote-section .show-persents .answer-box .circle:first-child {
          margin-right: 0;
          margin-bottom: 30px; }
    .vote-section .vote-choise .vote-circle {
      margin-left: auto;
      margin-right: auto;
      margin-top: 32px;
      margin-bottom: 32px;
      width: 255px;
      height: 255px;
      border: 8px solid #22323f;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
    .vote-section .vote-choise .q {
      margin-left: 0px;
      text-align: center; }
    .vote-section .vote-choise .answer-box.type-2 {
      justify-content: space-between; }
      .vote-section .vote-choise .answer-box.type-2 .button-question {
        height: 55px;
        width: 47.6%; }
        .vote-section .vote-choise .answer-box.type-2 .button-question:first-child {
          margin-right: 0px; }
    .vote-section .vote-choise .answer-box {
      margin-top: 24px;
      margin-left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .vote-section .vote-choise .answer-box .button-question {
        width: 45%;
        padding: 13px 24px; }
        .vote-section .vote-choise .answer-box .button-question:first-child {
          margin-right: 16px; } }

.more-posts__carousel-buttons p {
  display: none; }

.more-posts {
  padding-bottom: 132px;
  padding-top: 80px;
  overflow: hidden; }
  .more-posts shadow {
    background: #ffffff !important; }

.more-posts .swiper-container {
  margin-left: calc(50% - 620px) !important; }

@media (max-width: 1024px) {
  .more-posts .swiper-container {
    margin-left: calc(50% - 540px) !important; }
  .homepage-quote {
    padding-left: 15px; } }

@media (max-width: 768px) {
  .more-posts .swiper-container {
    margin-left: 0 !important; } }

.more-posts__title .h1 svg {
  margin-left: 30px; }

.more-posts__title .b2 {
  max-width: 382px;
  margin-top: 24px; }

.more-posts__carousel-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 37px; }
  .more-posts__carousel-buttons p {
    margin-right: 20px; }
  .more-posts__carousel-buttons .button-prev-slide {
    margin-right: 8px; }

@media (max-width: 767px) {
  .more-posts__carousel-buttons {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 30px; }
    .more-posts__carousel-buttons .button-prev-slide,
    .more-posts__carousel-buttons .button-next-slide {
      display: none; } }

.more-posts__more-info {
  text-align: center; }

.more-posts .swiper-slide.hide {
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  -webkit-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
  -ms-transform: rotate(-30deg) translateX(-600px) translateY(-211px);
  transform: rotate(-30deg) translateX(-600px) translateY(-211px); }
  .more-posts .swiper-slide.hide .court-case::after {
    border: 1px solid #bebebe; }
  .more-posts .swiper-slide.hide.show {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-transform: rotate(0) translateX(0) translateY(0) !important;
    -ms-transform: rotate(0) translateX(0) translateY(0) !important;
    transform: rotate(0) translateX(0) translateY(0) !important; }
    .more-posts .swiper-slide.hide.show .court-case::after {
      border-right: 1px solid transparent; }

@media (min-width: 992px) {
  .more-posts .swiper-slide .court-case::after {
    border-right: none; }
  .more-posts .swiper-slide:last-child .court-case::after {
    border-right: 1px solid #bebebe; } }

@media (max-width: 575px) {
  .more-posts .swiper-container {
    margin: 0 15px; } }

#in-develop {
  background: url(../img/bg/inDevelop.png) no-repeat center center;
  background-size: cover;
  min-height: 713px; }
  #in-develop h1 {
    margin-top: 102px;
    margin-bottom: 38px; }
  #in-develop h2 {
    margin-bottom: 38px; }
  #in-develop .button-red {
    width: 454px;
    max-width: 100%; }

.submenu__item .chevron-icon {
  display: none; }

.dropdown-button .dropdown-list-container.show {
  border: 1px solid #bebebe;
  background: white; }

.dropdown-button .dropdown-list-container__item:hover {
  background: #ffffff; }
  .dropdown-button .dropdown-list-container__item:hover a {
    color: #d17077; }

.dropdown-list-container__item a {
  color: #000000;
  display: block;
  padding-top: 2px;
  padding-left: 15px;
  padding-bottom: 2px;
  width: 100%;
  height: 100%; }

@media screen and (max-width: 576px) {
  .detail-page .breadcrumbs ul li a {
    font-size: 14px; } }

.cloud-message {
  position: relative; }

.cloud-message__cloud {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  background: #f7f7fc;
  z-index: 100000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 500px;
  font-size: 12px; }

.cloud-message__close {
  color: #aa242d;
  cursor: pointer; }

@media (max-width: 575px) {
  .cloud-message__cloud {
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px;
    background: #f7f7fc;
    z-index: 100000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width: 95%;
    font-size: 12px; } }

table,
tr,
td,
tbody {
  border-width: 1px; }

td {
  padding: 5px; }

.not-block {
  position: fixed;
  right: 5px;
  top: 5px;
  padding: 5px;
  background: #f7f7fc;
  font-size: 14px;
  text-align: center;
  z-index: 10000;
  width: 400px; }

.not-block__close {
  color: #aa242d;
  cursor: pointer; }

@media (max-width: 767px) {
  .not-block {
    left: 50%;
    top: unset;
    bottom: 5px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 95%;
    font-size: 12px;
    z-index: 10000; } }

.case__table th {
  padding: 10px; }

.swiper-slide:last-child .feedback-box {
  border-right: 1px solid #415559; }

.sidebar-button:hover p {
  color: #f09d9a; }

.sidebar-button:hover svg path {
  fill: #f09d9a; }

.created-shadow {
  -webkit-filter: blur(40px);
  filter: blur(40px);
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 35px solid rgba(27, 27, 27, 0.22); }

body .tox .tox-toolbar,
body .tox .tox-toolbar__overflow,
body .tox .tox-toolbar__primary {
  background-color: #f7f7fc; }

body .tox-tinymce:not(.tox-tinymce-inline) .tox-editor-header:not(:first-child) .tox-toolbar-overlord:first-child .tox-toolbar__primary {
  border-top: none; }

body#tinymce {
  background: red; }

body#tinymce p {
  line-height: 22px;
  color: red; }

.tox-tinymce:not(.tox-tinymce-inline) .tox-editor-header:not(:first-child) .tox-toolbar:first-child {
  border-top: none; }

body .tox .tox-edit-area__iframe {
  background-color: #f7f7fc;
  border: 0; }

body .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: none; }

body .tox-tinymce {
  border: none; }
  body .tox-tinymce:not(.tox-tinymce-inline) .tox-editor-header:not(:first-child) .tox-toolbar:first-child {
    border-top: none; }

.placeholder {
  z-index: 9; }

.editor:hover .placeholder,
.comment-placeholder.keyup {
  top: -15px; }

.header-simple-page__background.desktop, .header-simple-page__background.mobile {
  display: none; }

@media (min-width: 575px) {
  .header-simple-page__background.desktop {
    display: block; } }

@media (max-width: 574px) {
  .header-simple-page__background.mobile {
    display: block; }
  .quote-section {
    padding-top: 0;
    padding-bottom: 72px; }
  .quote-section__quote {
    font-family: Gilroy;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 119.9%;
    text-align: center;
    color: #22323f; }
  .quote-section .h3 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 104.4%;
    text-align: center;
    color: #22323f; } }

.affairs-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  .affairs-item .affairs-item__content {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .affairs-item:hover {
    background: #ffffff;
    -webkit-box-shadow: -2px 7px 23px rgba(0, 0, 0, 0.11);
    box-shadow: -2px 7px 23px rgba(0, 0, 0, 0.11); }
    .affairs-item:hover .affairs-item__content {
      border: 1px solid transparent; }

.set-images.new-comment-image {
  width: 50px;
  position: absolute;
  z-index: 99;
  height: 50px;
  bottom: 10px;
  right: 10px; }

.comment-reply {
  display: none; }

.user-comment-info {
  display: none !important; }
  .user-comment-info.show {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }

.img-input .output-image {
  height: 50px;
  overflow: hidden;
  position: absolute;
  width: 50px;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 10px;
  opacity: 0.8; }
  .img-input .output-image img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute; }

.hidden-comment,
.hidden-sub-comment {
  display: none; }

.show-comment {
  display: block; }

.img-container {
  padding-top: 10px; }

.comment-picture {
  max-width: 200px;
  max-height: 150px; }

.message {
  padding-top: 15px;
  font-weight: 600; }

@media screen and (max-width: 575px) {
  .comments-section h4,
  .comments-section .h2 {
    font-weight: bold;
    font-size: 25px;
    font-family: Gilroy;
    /* text-align: center; */ }
  .comments-section .hidden-form .name-container,
  .comments-section .hidden-form .email-container {
    margin-top: 10px; }
  .comments-list__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; } }

span.output {
  color: #f09d9b; }

@media (max-width: 575px) {
  .homepage-jury-trial__title .h1 svg {
    width: 27px;
    height: 20, 69px; }
  .homepage-jury-trial .f-c {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 16px; }
    .homepage-jury-trial .f-c .homepage-jury-trial__carousel-buttons p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150.69%;
      color: #000000; }
  #feedbacks .swiper-container {
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important; }
  .homepage-jury-trial__carousel-buttons {
    margin-bottom: 15px; }
  .detail-page__content .text-content h2 {
    font-size: 16px; }
  body .slider-section_top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
  body #feedbacks .swiper-container {
    padding-top: 15px; } }

/* court header */
.court-header {
  height: 641px;
  background: gray;
  position: relative;
  overflow: hidden; }

.court-header__desktop,
.court-header__mobile {
  display: none; }

@media (min-width: 767px) {
  .court-header__desktop {
    display: block; } }

@media (max-width: 767px) {
  .court-header__mobile {
    display: block; } }

.court-header__bg {
  position: absolute;
  right: 0; }

.court-header__wrapper {
  position: absolute;
  width: 74.5%;
  height: 100%;
  background: #ffffff;
  -webkit-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
  transform: skew(-20deg);
  left: -117px;
  top: 0;
  z-index: 10; }

.court-header__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 52px;
  color: #22323f;
  margin-top: 187px;
  max-width: 434px; }

.court-header__desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 132.19%;
  color: #22323f;
  margin-top: 32px;
  max-width: 410px; }

.court-header .breadcrumbs {
  padding-top: 17px; }
  .court-header .breadcrumbs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none; }
    .court-header .breadcrumbs ul li a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #1e1e1e; }
    .court-header .breadcrumbs ul li:after {
      content: "/";
      color: #1e1e1e;
      margin-left: 5px;
      margin-right: 5px; }
    .court-header .breadcrumbs ul li:last-child:after {
      display: none; }

.court-header .container-default {
  position: relative;
  z-index: 20; }

@media (max-width: 575px) {
  .court-header {
    height: 682px; }
  .court-header__bg {
    width: 100%;
    height: 424px;
    -o-object-fit: cover;
    object-fit: cover; }
  .court-header__wrapper {
    width: 150%;
    height: 446.18px;
    background: #ffffff;
    -webkit-transform: rotate(12.84deg);
    -ms-transform: rotate(12.84deg);
    transform: rotate(12.84deg);
    left: -98.14px;
    top: 378px; }
  .court-header__title {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 104.4%;
    color: #22323f;
    margin-top: 398px; }
  .court-header__desc {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 132.19%;
    color: #22323f;
    margin-top: 24px; }
    .court-header__desc::after {
      content: "";
      display: block;
      margin-top: 50px;
      height: 1px;
      width: 260px;
      margin-left: auto;
      margin-right: auto;
      background: #e8e8e8; }
  .court-header .breadcrumbs {
    padding-top: 17px; }
    .court-header .breadcrumbs ul li a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff; }
    .court-header .breadcrumbs ul li:after {
      content: "/";
      color: #ffffff;
      margin-left: 5px;
      margin-right: 5px; }
    .court-header .breadcrumbs ul li:last-child:after {
      display: none; } }

/* donate block */
.donate-block {
  position: relative;
  z-index: 1;
  height: 610px;
  background: #22323f;
  padding: 0 18px; }

.donate-block__title-container {
  padding-top: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.donate-block__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 97.8%;
  letter-spacing: -0.02em;
  color: #ffffff;
  width: 517px; }

.donate-block__subtitle {
  width: 251px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 14px;
  font-size: 16px;
  line-height: 132.19%;
  color: #ffffff; }

.donate-block__calculator-container {
  padding-top: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.donate-block__form {
  width: 700px;
  height: 306px;
  padding-left: 49px;
  padding-right: 56px;
  padding-top: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 49px; }

.donate-block__form__bottom {
  padding-top: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .donate-block__form__bottom .input-donate {
    margin-right: 16px; }

.donate-block__regular {
  margin-left: 20px;
  padding-top: 44px;
  padding-left: 30px;
  padding-right: 30px;
  width: 300px;
  height: 306px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 49px; }

.donate-block__regular__title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 111.5%;
  color: #dff0f4;
  margin-bottom: 61px;
  margin-right: 15px; }

.donate-block__regular__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 240px;
  height: 65px;
  background: #c83e47;
  border-radius: 59px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 96%;
  color: #dff0f4;
  text-decoration: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  .donate-block__regular__button:hover {
    color: #fff;
    cursor: pointer;
    background: #d17077; }

@media (max-width: 993px) {
  .donate-block {
    height: unset;
    padding-bottom: 66px; }
  .donate-block__title-container {
    padding-top: 0;
    display: unset; }
  .donate-block__title {
    padding-top: 60px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 97.8%;
    letter-spacing: -0.02em;
    color: #ffffff;
    width: 100%; }
  .donate-block__subtitle {
    padding-bottom: 50px;
    padding-top: 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 132.19%;
    color: #ffffff; }
  .donate-block__calculator-container {
    padding-top: 50px;
    display: unset; }
  .donate-block__form {
    padding-top: 60px;
    margin-bottom: 20px;
    height: unset;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 51px; }
  .donate-block__form__bottom {
    padding-top: 0;
    display: unset; }
    .donate-block__form__bottom .input-donate {
      margin-top: 54px;
      margin-right: 0;
      margin-bottom: 24px; }
  .donate-block__form__range {
    padding-left: 10px;
    padding-right: 10px; }
  .donate-block__regular {
    margin-left: 0px;
    padding-top: 40px;
    padding-left: 27px;
    padding-right: 35px;
    width: 100%;
    height: 238px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 49px; }
  .donate-block__regular__title {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 111.5%;
    color: #dff0f4;
    margin-bottom: 32px; }
  .donate-block__regular__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 65px;
    background: #c83e47;
    border-radius: 59px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 96%;
    color: #dff0f4;
    text-decoration: none; }
  .sidebar {
    max-width: 520px;
    margin-left: 30px; } }

.donate-block.light {
  background: #fff; }
  .donate-block.light .donate-block__title,
  .donate-block.light .donate-block__subtitle {
    color: #22323f; }
  .donate-block.light .donate-block__regular__title.color-dark {
    color: #22323f !important; }
  .donate-block.light .donate-block__form,
  .donate-block.light .donate-block__regular {
    border: 1px solid rgba(34, 50, 63, 0.2); }

.input-range {
  padding-top: 75px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .input-range .line {
    width: 100%;
    height: 2px;
    background: #fff;
    position: relative; }
    .input-range .line .dot {
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      border: 2px solid #fff;
      border-radius: 100%;
      background: #22323f;
      width: 12px;
      height: 12px;
      cursor: pointer; }
    .input-range .line .dot__title {
      position: absolute;
      top: -75px;
      left: -12px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      color: #fff;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
    .input-range .line .dot:nth-child(3) .dot__title,
    .input-range .line .dot:nth-child(4) .dot__title {
      left: -20px; }

@media (max-width: 767px) {
  .input-range .line .dot__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    left: -20px;
    top: -45px; }
  .donate-block .container-default {
    width: 100%; } }

.input-range__trigger {
  width: 70px;
  height: 70px;
  background: #fff;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: -webkit-grab;
  cursor: grab; }
  .input-range__trigger.active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  .input-range__trigger.moving {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }

.input-range.light .input-range__trigger {
  width: 70px;
  height: 70px;
  background: #22323f;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: -webkit-grab;
  cursor: grab; }
  .input-range.light .input-range__trigger svg rect {
    fill: #fff; }
  .input-range.light .input-range__trigger.active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  .input-range.light .input-range__trigger.moving {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }

.input-range.light .line {
  width: 100%;
  height: 2px;
  background: #22323f;
  position: relative; }
  .input-range.light .line .dot {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    border: 2px solid #22323f;
    border-radius: 100%;
    background: #fff;
    width: 8px;
    height: 8px;
    cursor: pointer; }
  .input-range.light .line .dot:nth-child(3) .dot__title,
  .input-range.light .line .dot:nth-child(4) .dot__title {
    left: -20px; }
  .input-range.light .line .dot__title {
    position: absolute;
    top: -75px;
    left: -12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #22323f;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }

@media (max-width: 767px) {
  .input-range.light .line .dot__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #22323f;
    left: -20px;
    top: -45px; } }

@media (max-width: 575px) {
  .input-range {
    padding-top: 38px; }
  .input-range__trigger,
  .input-range.light .input-range__trigger {
    width: 48px;
    height: 48px; }
    .input-range__trigger svg,
    .input-range.light .input-range__trigger svg {
      width: 13.71px;
      height: 13.71px; } }

.input-donate {
  position: relative; }
  .input-donate .placeholder {
    position: absolute;
    left: 30px;
    top: -7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 112%;
    color: #ffffff;
    background: #22323f;
    padding-right: 10px;
    padding-left: 10px; }
  .input-donate input {
    width: 222px;
    height: 64px;
    border: 1px solid #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 49px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    background: transparent;
    padding-left: 39px;
    -webkit-appearance: unset;
    padding-right: 15px; }
  .input-donate.light .placeholder {
    background: #fff;
    color: #22323f; }
  .input-donate.light input {
    border: 1px solid #22323f;
    color: #22323f; }

@media (max-width: 575px) {
  .input-donate .placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 112%;
    color: #ffffff; }
  .input-donate input {
    width: 100%;
    height: 64px;
    border: 1px solid #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 49px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    background: transparent;
    padding-left: 39px;
    -webkit-appearance: unset;
    padding-right: 15px; } }

.donate-button {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 358px;
  height: 65px;
  background: #c83e47;
  border-radius: 59px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 96%;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none; }
  .donate-button svg {
    margin-left: 16px; }
  .donate-button:hover {
    cursor: pointer;
    background: #d17077; }

#show-more-comments {
  width: 292px;
  margin-left: calc(55% - 160px);
  padding: 12px 37px;
  text-align: center; }
  #show-more-comments .text {
    font-family: "Roboto"; }

#show-protocol {
  width: 299px;
  padding: 12px 50px; }

@media (max-width: 575px) {
  .donate-button {
    width: 100%;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 96%;
    color: #ffffff;
    text-transform: unset; }
    .donate-button svg {
      margin-left: 8px;
      width: 20px;
      height: 20px; }
  .achievement-box {
    margin-left: 0; } }

@media (max-width: 360px) {
  .homepage-header__top {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center; }
    .homepage-header__top .button-transparent {
      display: block;
      width: 100%; }
    .homepage-header__top .ur-help {
      padding-left: 0;
      margin-left: 0;
      width: 100%;
      max-width: 100%; }
  .homepage-header .button-red {
    width: 100%; }
  .homepage-achievements .achievement-box,
  .homepage-jury-trial .carousel-wrapper,
  .homepage-mass-media__carousel,
  #feedbacks .swiper-container {
    opacity: 1; } }

.breakpoint {
  position: absolute;
  top: 700px; }

.dark-pink-button {
  background-color: #d17077;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border: none;
  border-radius: 50px; }

@media (max-width: 370px) {
  .news-card__to-detail {
    font-size: 14px;
    max-width: 36%; }
  .homepage-quote__title {
    width: 100%; }
  .homepage-quote__collibri.appear {
    z-index: -1; }
  .sidebar__footer .left p {
    font-size: 13px; } }

@media (max-width: 500px) {
  .homepage-quote__quote {
    width: 100%; }
  .homepage-jury-trial .carousel-wrapper .swiper-container {
    margin-left: 0 !important; }
  .homepage-mass-media__carousel {
    padding-left: 0 !important; } }

.scroll-breakpoint {
  position: absolute;
  bottom: 0; }

.archive-h3 {
  font-weight: 600; }

@media (min-width: 994px) and (max-width: 1024px) {
  .donate-block__form {
    width: 680px; } }

@media (max-width: 575px) {
  .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
    opacity: 1; }
  .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
    transition: 1s;
    transform: none !important; } }

a {
  color: #7c7c7c; }

@media (max-width: 575px) {
  .homepage-jury-trial__carousel-buttons {
    margin-bottom: 0; }
  .homepage-header.short .homepage-header__numbers {
    padding-bottom: 25px; }
  .news-card__date {
    left: 24px; }
  .news-card__to-detail {
    color: #d17077;
    right: 24px; } }

.homepage-header.short .homepage-header__numbers {
  border-radius: 4px; }

@media (max-width: 568px) {
  .feedback-box {
    min-height: 425px; }
  .feedback-box {
    height: auto; } }

@media (min-width: 767px) and (max-width: 992px) {
  #site-navigation .dark .logo {
    height: 100%; }
  #site-navigation .dark img {
    height: 100%; } }

#site-navigation .dark .logo,
#site-navigation .dark img {
  height: 105%;
  position: relative;
  z-index: 100; }

.info {
  margin-bottom: 50px; }

@media screen and (max-width: 767px) {
  #site-navigation .dark a,
  #site-navigation .dark img {
    height: auto; }
  .number-box__text {
    font-size: 13.5px;
    margin-left: 22px;
    max-width: initial; }
  .number-box__number {
    min-width: 93px; }
  .button-pink {
    text-transform: capitalize; }
  .homepage-header__numbers {
    z-index: 10;
    top: 0;
    opacity: 1; }
  .detail-page__info .views {
    margin-right: 10px; }
  .more-posts .carousel-wrapper {
    overflow: hidden;
    padding-left: 1.2em; }
  .more-posts .swiper-container {
    overflow: initial; }
  .more-posts .swiper-container .swiper-slide-prev {
    opacity: 0; } }

.vote-section .vote-choise .vote-circle {
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto; }

.vote-section .vote-choise .answer-box.type-2 {
  margin-left: auto;
  margin-right: auto; }

.button-question {
  padding: 13px 35px;
  min-width: 180px; }

.vote-section .show-persents .answer-box .circle {
  position: relative;
  padding-left: 50px; }

.vote-section .show-persents .answer-box .circle::before {
  content: "";
  left: 8px;
  width: 31px;
  height: 31px;
  display: block;
  border-radius: 31px;
  position: absolute; }

.vote-section .vote-choise .answer-box {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  flex-wrap: wrap;
  gap: 1em; }

.vote-section .vote-choise .answer-box.custom-text button.button-question {
  flex: 1 1 auto; }

.vote-section .show-persents .answer-box {
  padding-left: initial; }

.vote-section .vote-choise .пanswer-box.type-2 {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

span.green {
  color: #00602c; }

span.red {
  color: #d17077; }

@media screen and (max-width: 1440px) {
  .homepage-header {
    height: 110vh; } }

@media screen and (max-height: 675px) {
  .homepage-header {
    height: 125vh; } }

@media screen and (max-width: 550px) {
  body .vote-section {
    background-position-x: 50%;
    background-position-y: 25%; }
  .button-question {
    padding: 13px 35px;
    min-width: 140px; }
  .vote-section .show-persents .answer-box .circle {
    font-size: 14px; }
  .homepage-header__top {
    display: flex;
    justify-content: space-between; }
  .homepage-header__top .button-transparent {
    font-size: 0.97rem; }
  .vote-section .left h3 {
    font-size: 34px; } }

.swiper-scrollbar {
  background: #d4d4d4; }

.district__title > a {
  color: #dff0f4; }

.sidebar__footer .social {
  margin-left: -9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 170px; }

.home-sidebar__footer .social {
  width: 170px; }

.sidebar__footer .left p {
  color: #a5a5a5; }

.home-container.more-info-container .button-default {
  color: #f09d9b;
  border: 2px solid #f09d9b; }

.split span::after {
  content: " "; }

.scrool-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-align: center;
  z-index: 100; }

.scrool-top .button-next-slide {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: #ffffffcc; }

.scrool-top span {
  color: #d17077;
  font-size: 14px; }

.scrool-top .button-next-slide:hover {
  background-color: #d17077; }

.scrool-top .button-next-slide:hover svg path {
  z-index: 1000; }

.scroll-top-main.show {
  visibility: visible;
  opacity: 1 !important; }

.scroll-top-main.show._none {
  visibility: hidden;
  opacity: 0 !important;
  pointer-events: none; }

#top {
  height: 0;
  width: 0; }

.detail-page__content .text-content img {
  height: auto; }

.text-content h2 {
  font-family: Gilroy;
  font-style: normal;
  font-size: 30px;
  line-height: 122.49%;
  margin-bottom: 16px;
  margin-top: 8px; }

.detail-page__content .text-content p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  word-break: break-word; }
  .detail-page__content .text-content p strong {
    font-weight: 400;
    font-size: 18px; }

.text-content ol,
.text-content ul {
  margin-left: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  word-break: break-word; }

.text-content figure ul {
  margin-left: initial;
  margin-bottom: 48px; }

.text-content .wp-block-gallery.columns-2 .blocks-gallery-grid {
  margin-bottom: 0; }

.text-content .wp-block-gallery .blocks-gallery-item img {
  width: 100%; }

.text-content .wp-block-gallery .blocks-gallery-item figure {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.text-content iframe {
  margin-bottom: 40px;
  margin-top: 15px; }

.comment-box__date {
  margin-left: 5px; }

.comment-box__title {
  margin-right: 16px; }

.homepage-mass-media .homepage-mass-media__carousel #homepage-mass-media-scrollbar {
  width: 100%; }

.more-posts .carousel-wrapper .swiper-container #more-posts-scrollbar {
  width: 100%; }

.editor .wrap-editor {
  border-radius: 10px !important; }

.donate-checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px; }

.donate-checkbox input {
  margin: 0;
  width: 20px;
  height: 20px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: #1b1b1b;
  vertical-align: middle;
  appearance: none;
  border: 0.08em solid #4f4f4f; }

.donate-checkbox input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='32' width='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.4 21.5-5.93-5.93-2.01 2.01 7.94 7.94 17.1-17.1-2.01-2.01z' fill='%23fff'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #d17077;
  border-color: #d17077; }

.donate-checkbox label {
  margin-bottom: 0;
  color: #fff;
  max-width: 370px;
  margin-left: 15px; }

.donate-block__form {
  height: auto;
  padding-bottom: 52px; }

.donate-block__regular {
  height: auto; }

.donate-checkbox-white label {
  color: #22323f; }

@media (max-width: 620px) {
  .donate-checkbox label {
    width: 80%;
    max-width: none; }
  .donate-checkbox label {
    font-size: 13px; }
  .detail-page__info .share {
    margin-top: 5px; }
  .detail-page__info {
    margin-bottom: 23px; }
  footer#footer .footer-top .container-default .right .info {
    border-top: 1px solid #cbcbcb;
    padding-top: 33px; }
  .footer-top .container-default .right {
    padding-bottom: 14px;
    border-bottom: 1px solid #cbcbcb; }
  .donate-block__calculator-container-single-case .donate-block__form {
    padding-left: 10px;
    padding-right: 10px;
    border: none !important; }
  .donate-block__calculator-container-single-case .donate-block__regular {
    padding-left: 0;
    padding-right: 0;
    border: none !important; }
  .detail-page__content .text-content h2 {
    font-style: normal;
    font-size: 25px;
    line-height: 122.49%; }
  .detail-page__content .text-content p {
    line-height: 150.69%;
    font-size: 16px; }
  .detail-page__content .wp-block-gallery .blocks-gallery-item {
    margin: 0 0em 1em 0; }
  .detail-page__content .wp-block-gallery .blocks-gallery-item {
    width: calc(100% - 1em); }
  .container-default {
    width: 90%; }
  body .tox-tinymce {
    max-height: 120px; }
  .editor {
    min-height: 100px; }
  .homepage-header.appear-section .homepage-header__numbers {
    top: 100%; }
  .slider-section .court-case__tags {
    margin-top: 5px;
    font-size: 15px;
    display: none; }
  .detail-page__content .text-content ol,
  .detail-page__content .text-content ul {
    font-size: 16px; }
  .homepage-header .button-red,
  #in-develop .button-red {
    padding: 20px 18px;
    font-size: 16px;
    justify-content: space-evenly; }
  .homepage-jury-trial .f-c {
    flex-wrap: wrap;
    margin-bottom: 5px; }
  .homepage-jury-trial .carousel-wrapper .swiper-container {
    padding-left: 18px; }
  .swiper-container-horizontal > .swiper-scrollbar {
    height: 3px;
    width: calc(100% - 18px);
    margin-left: 0;
    margin-right: auto; }
  .more-posts .swiper-container {
    margin: 0 7px; }
  .more-posts .carousel-wrapper {
    padding-left: 0;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    overflow: initial; }
  .sidebar__footer .social {
    margin: 0 auto !important; }
  .tox .tox-edit-area__iframe {
    height: 150px  !important; } }

@media (max-width: 450px) {
  body .vote-section .vote-choise .answer-box .button-question {
    width: 45%;
    padding: 13px 10px;
    font-size: 14px; } }

.scrool-top.screen {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  bottom: 20px; }

.scrool-top.screen.show {
  opacity: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }

.detail-page__content .text-content img {
  margin-bottom: 20px;
  margin-top: 20px; }

.show-sub-more .text {
  color: #d17077;
  cursor: pointer; }

/* width */
.sidebar__content::-webkit-scrollbar {
  width: 2px; }

/* Track */
.sidebar__content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #22323f; }

/* Handle */
.sidebar__content::-webkit-scrollbar-thumb {
  background: #22323f;
  border-radius: 10px; }

/* Handle on hover */
.sidebar__content::-webkit-scrollbar-thumb:hover {
  background: #22323f; }

.sidebar__header a span {
  display: none; }

@media (min-width: 340px) {
  .more-posts__carousel-buttons {
    margin-top: -50px; } }

@media (min-width: 340px) and (max-width: 767px) {
  .home-container.homepage-jury-trial__more-info .button-default {
    /* min-width: 340px; */
    width: 100%; } }

@media (min-width: 992px) {
  .sidebar__header a span {
    display: inline-block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #dff0f4;
    position: relative;
    top: 5px; }
  .sidebar__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .sidebar .close-sidebar {
    position: relative;
    top: initial; }
  .home-container h1.h1 {
    font-size: 45px;
    line-height: 95.9%; } }

.filter-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: black; }

.button-dark[disabled] {
  opacity: 0.5; }

.button-dark[disabled]:hover {
  background: #22323f;
  border: 1px solid #22323f; }

.fixed-path {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0; }

.fixed-post-info.fixed {
  position: fixed;
  top: 135px; }

.court-header {
  height: 641px;
  background: #020202;
  position: relative;
  overflow: hidden; }
  .court-header__row {
    display: none;
    width: 640px;
    height: 1px;
    background: #E8E8E8;
    margin: 56px auto 0; }

.court-header__bg {
  position: absolute;
  left: calc(100% / 2.6);
  min-width: 50vw;
  min-height: 100%; }

.court-header__wrapper {
  width: 50vw; }

@media (max-width: 1200px) {
  .court-header__bg {
    left: calc(100% / 3); }
  .court-header__wrapper {
    width: 54vw; } }

@media (min-width: 992px) and (max-width: 1023px) {
  .court-header__bg {
    left: calc(100% / 3.7); }
  .court-header__wrapper {
    width: 70vw; } }

@media (max-width: 992px) {
  .donate-block__regular {
    padding-bottom: 40px; }
  .court-header {
    height: 1000px; }
  .court-header__wrapper {
    width: 199%;
    -webkit-transform: rotate(-11.16deg);
    -ms-transform: rotate(-11.16deg);
    transform: rotate(-11.16deg);
    top: 574px;
    left: -20%; }
  .court-header .container-default {
    position: relative;
    height: 100%;
    width: 100%; }
  .court-header__title {
    margin-top: 680px;
    max-width: initial;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .court-header__desc {
    max-width: 55vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .court-header__bg {
    left: 0;
    min-height: auto; }
  img.court-header__mobile.court-header__bg {
    display: none; }
  .court-header .breadcrumbs {
    margin-left: 20px; }
  .court-header .breadcrumbs ul li a {
    color: #ffffff; }
  #feedbacks .swiper-container {
    padding-top: 35px; }
  .more-posts .more-posts__carousel-buttons {
    margin-top: initial; } }

@media (max-width: 767px) {
  img.court-header__desktop.court-header__bg {
    display: none; }
  img.court-header__mobile.court-header__bg {
    display: block;
    min-height: 450px;
    height: auto;
    -o-object-position: initial;
    object-position: initial;
    width: 100%; }
  .court-header__wrapper {
    width: 199%;
    -webkit-transform: rotate(11.16deg);
    -ms-transform: rotate(11.16deg);
    transform: rotate(11.16deg);
    top: 440px;
    left: -20%; }
  .court-header {
    height: 750px; }
  .court-header__title {
    margin-top: 460px;
    max-width: initial;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left; }
  .court-header__desc {
    max-width: initial;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left; } }

@media (min-width: 992px) {
  .homepage-header__numbers {
    opacity: 0;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s; }
  .homepage-header.appear-section.short .homepage-header__numbers {
    opacity: 1; }
  .homepage-header {
    background-position-y: initial; }
  .homepage-header__numbers {
    z-index: 10;
    top: -15%;
    opacity: 1; } }

@media (min-width: 992px) and (min-height: 800px) {
  .homepage-header {
    height: 90vh; }
  .homepage-header__numbers {
    opacity: 1; } }

@media (min-width: 992px) and (min-height: 1366px) {
  .homepage-header {
    height: 55vh; } }

@media (height: 1366px) and (width: 1024px) {
  .homepage-header__numbers {
    top: 90%; } }

@media (min-width: 767px) and (max-width: 992px) {
  body .homepage-header__numbers {
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 96%;
    top: -135px;
    height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 1;
    left: auto;
    transform: unset; }
  .appear-section.short .homepage-header__numbers {
    top: -5vmax; }
  .number-box {
    flex: 0 0 50%;
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
    display: flex;
    align-items: center;
    min-height: 125px; }
  .number-box:nth-child(1) {
    border-bottom: 1px solid #f09d9a;
    border-right: 1px solid #f09d9a; }
  .number-box:nth-child(2) {
    border-bottom: 1px solid #f09d9a; }
  .number-box:nth-child(3) {
    border-right: 1px solid #f09d9a; }
  .number-box::after {
    content: "";
    display: none; }
  .number-box__number {
    min-width: 95px;
    margin-right: 25px; }
  .homepage-quote__title {
    padding-top: 0;
    padding-left: 20px; } }

.created-shadow {
  display: none; }

.homepage-header__numbers {
  -webkit-box-shadow: 0px 10px 40px 0px #1b1b1b26;
  box-shadow: 0px 10px 40px 0px #1b1b1b26; }

@media (min-width: 1200px) {
  .detail-page .donate-block > div {
    max-width: 85%;
    margin-right: auto;
    margin-left: auto; }
  .detail-page .donate-block__calculator-container {
    padding-top: 58px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start; } }

@media (min-width: 992px) and (max-width: 1024px) {
  .breadcrumbs,
  .court-header__title,
  .court-header__desc {
    margin-left: 30px; } }

/* Исправление меню */
.homepage-header__numbers {
  max-width: 100%; }

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .homepage-offset .home-sidebar__content ul li ul.sub-menu {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); } }

.homepage-offset .home-sidebar__content ul li a .chevron-icon {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }

.homepage-offset .home-sidebar__content ul li a .chevron-icon svg {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s; }

.homepage-offset .home-sidebar__footer {
  padding-left: 90px; }

@media (min-width: 992px) and (max-width: 1280px) {
  .homepage-quote {
    width: 100%; }
  .homepage-quote__quote {
    position: relative;
    top: 300px;
    right: 10%;
    float: right; }
  .homepage-quote__quote {
    right: 20px;
    bottom: 0; } }

.comments-section {
  overflow: hidden;
  padding-bottom: 111px; }

@media (min-width: 1024px) and (max-width: 1024px) {
  .court-header__wrapper {
    width: 58vw; } }

@media (max-width: 1024px) {
  .feedback-box {
    min-height: 424px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

.affairs-list__load-container .shadow-appear {
  opacity: 1; }

.h1 sup {
  top: -1em; }

.affairs-item.affair-small .affairs-item__date {
  bottom: 48px; }

.affairs-item.affair-flex .affairs-item__date {
  bottom: 45px; }

.affairs-item__date {
  position: absolute;
  bottom: 32px; }

.court-case_status {
  color: #000000;
  margin-bottom: 50px;
  height: 0; }

.court-case_status.green span {
  color: #00602c;
  font-weight: 600; }

.court-case_status.red span {
  color: #d57c83;
  font-weight: 600; }

.detail-page .share-block-container .views svg {
  margin-right: 10px; }

.detail-page .share-block-container .views svg {
  margin-right: 10px; }

.detail-page .share-block-container .comments svg {
  margin-right: 10px; }

@media (max-width: 992px) {
  #site-navigation.show .logo {
    opacity: 0;
    visibility: hidden; }
  .no-gutters .ml-auto {
    margin-left: 0 !important; } }

@media (max-width: 767px) {
  .sidebar__content {
    top: 85px;
    height: 90vh;
    width: 96%;
    padding-top: 0;
    padding-right: 15px; }
  .sidebar {
    width: calc(100% + 125px); }
  .close-sidebar svg {
    position: absolute;
    right: 15px; }
  footer#footer .footer-bottom {
    min-height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .affairs-list__filter .filter-button {
    text-align: center;
    display: inline-flex;
    justify-content: center; }
  .affairs-list__filter {
    margin-bottom: 32px; }
  .donate-block__form {
    padding-left: 40px;
    padding-right: 40px; } }

.content-scrool .wrap h3 {
  font-weight: normal !important; }

.content content-scrool .wrap p span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px; }

.court-case__text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  height: 6.5em;
  margin-bottom: 12px; }

@media (max-width: 575px) {
  .affairs-item__image {
    height: 211px; }
  .affairs-item__title {
    font-size: 20px;
    line-height: 122.49%;
    margin-bottom: 16px; }
  .affairs-item__desc {
    font-size: 14px;
    margin-bottom: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
    height: 5.1em;
    margin-bottom: 40px; }
  .detail-page__info .updated-at {
    font-size: 14px; }
  .detail-page__info .status {
    margin-top: 7px; }
  .detail-page__info .views {
    display: flex;
    flex-direction: revert;
    align-items: center;
    column-gap: 5px;
    margin-top: 15px; }
  .detail-page__info .comments {
    margin-top: 14px; }
  .homepage-quote {
    min-height: 900px; }
  .homepage-mass-media__carousel-buttons {
    flex: 1 1 100%;
    justify-content: flex-start; }
  .homepage-mass-media .home-container {
    flex-wrap: wrap;
    margin-bottom: 15px; }
  .homepage-mass-media__carousel-buttons button {
    display: none; }
  .homepage-mass-media .h1 {
    margin-bottom: 30px; }
  .homepage-header__numbers {
    z-index: 10;
    top: 2%;
    opacity: 1; }
  footer#footer .footer-bottom .container-default p.made-by {
    display: inline-block; } }

.fixed-post-info .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 5px 4px 0 0; }

.fixed-post-info .ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
  height: 30px;
  width: 30px;
  background-size: 30px 30px;
  background-position: center center; }

.share .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 5px 4px 0 0; }

.detail-page__info .share p {
  margin-right: 10px; }

.text-content .wp-block-gallery.columns-2 {
  margin-bottom: 40px; }

#case-190 .columns-2 .blocks-gallery-grid .blocks-gallery-item:first-child {
  width: calc(27% - 1em); }

#case-190 .blocks-gallery-grid .blocks-gallery-item:first-child {
  margin-bottom: initial; }

.affairs-list__load-container .affairs-item__to-detail.material:hover {
  background-color: #d17077; }

.input-range .line .dot__title {
  transform: translateX(-15%); }

.archive .donate-block > .container-default {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto; }

.editor .wrap-editor {
  border-radius: 6px;
  overflow: hidden; }

.donate-block__regular__title {
  color: white !important; }
  .donate-block__regular__title br {
    display: none; }

.donate-block__regular__button {
  color: white; }

.news-card__to-detail {
  font-style: initial; }
  .news-card__to-detail svg {
    margin-left: 9px; }

.slider-section_top {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.swiper-container-horizontal > .swiper-scrollbar {
  height: 3px; }

#footer .info > a {
  display: none; }

#footer .contact-info {
  margin-bottom: 10px; }

.filter-button:hover {
  color: #f09d9a; }

.filter-button.active {
  border: 2px solid #f09d9b; }

@media (max-width: 1300px) {
  .homepage-quote__title {
    top: 155px; }
  .homepage-quote__quote {
    right: 52px;
    top: 260px;
    width: 395px; } }

@media (min-width: 767px) and (max-width: 992px) {
  .homepage-quote__title {
    position: relative;
    font-size: 48px;
    max-width: 500px;
    top: 50px; }
  .homepage-quote__quote {
    width: 478px;
    position: relative;
    top: 86px;
    right: 0;
    float: right; }
  .homepage-quote {
    padding-top: 150px; } }

@media (min-width: 992px) {
  .homepage-quote .home-container {
    max-width: 1020px;
    width: 100%; } }

@media (min-width: 993px) {
  .sidebar__header {
    padding-left: 90px; } }

.homepage-achievements .home-container {
  max-width: 100%; }

p.views svg {
  margin-right: 2px; }

body .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_whatsapp .ya-share2__icon {
  background-image: url("data:image/svg+xml,%0A%3Csvg id='Слой_1' data-name='Слой 1' xmlns='http://www.w3.org/2000/svg' width='150' height='150' viewBox='0 0 150 150'%3E%3Cpath d='M75,20.21h0a54.76,54.76,0,0,0-44.35,86.91l-6.83,20.35,21.06-6.73A54.78,54.78,0,1,0,75,20.21ZM106.9,97.58c-1.33,3.73-6.57,6.83-10.76,7.73-2.86.61-6.6,1.1-19.19-4.12-16.1-6.67-26.47-23-27.28-24.09s-6.51-8.67-6.51-16.53a17.52,17.52,0,0,1,5.61-13.34,7.94,7.94,0,0,1,5.6-2c.68,0,1.29,0,1.84.06,1.61.07,2.41.16,3.48,2.7,1.32,3.19,4.54,11,4.92,11.86a3.26,3.26,0,0,1,.23,3,9.38,9.38,0,0,1-1.77,2.51c-.81.93-1.58,1.64-2.38,2.64s-1.58,1.81-.65,3.41a48.49,48.49,0,0,0,8.89,11A40.33,40.33,0,0,0,81.78,90.4a3.49,3.49,0,0,0,3.86-.61,66.1,66.1,0,0,0,4.28-5.67,3.08,3.08,0,0,1,3.94-1.2c1.48.52,9.3,4.39,10.91,5.19s2.67,1.19,3.07,1.87S108.22,93.84,106.9,97.58Z'/%3E%3C/svg%3E");
  background-size: 21px 30px; }

.safari .homepage-offset .home-sidebar__content ul li ul.sub-menu {
  background: #22323f; }

.site-navigation {
  transition: 0.5s;
  top: 0; }

.fixed-bottom {
  top: -100px !important;
  transition: 0.5s; }

.text-content a.wp-block-button__link {
  display: inline-block;
  padding: 12px 46px;
  font-family: "Open Sans";
  border-radius: 59px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 147.69%;
  color: #d17077 !important;
  border: 2px solid #d17077;
  position: relative;
  overflow: hidden;
  background: transparent;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  cursor: pointer;
  min-width: 300px; }

.wp-block-buttons > .wp-block-button {
  margin-bottom: 15px; }

.text-content a {
  color: #d17077; }

.text-content a:hover {
  color: #d17077; }

a.bt-popup:hover {
  color: white; }

.text-content table {
  border-collapse: collapse; }

.text-content table td {
  padding: 15px; }

.text-content table thead td {
  background-color: #54585d;
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #54585d; }

.text-content table tbody td {
  color: #636363;
  border: 1px solid #dddfe1; }

.text-content table tbody tr {
  background-color: #f9fafb; }

.text-content table tbody tr:nth-child(odd) {
  background-color: #ffffff; }

.wp-block-table {
  overflow-y: auto;
  max-width: 100%;
  margin-bottom: 20px; }

.sidebar__content .district ul {
  overflow: hidden; }

.develop-by {
  font-size: 14px !important;
  font-weight: 600 !important;
  justify-content: flex-end; }

.develop-by img {
  margin-left: 8px; }

@media (min-width: 991px) and (max-width: 1199px) {
  footer#footer .footer-top .right {
    gap: 1em; }
  footer#footer .footer-top .right .footer-menu-list {
    width: 23%; } }

@media (min-width: 1200px) {
  footer#footer .footer-top .right .footer-menu-list {
    width: initial;
    flex: 1 1 23%; }
  footer#footer .footer-top .right {
    padding-top: 85px; }
  footer#footer .footer-top {
    height: 435px; } }

.sidebar__content {
  max-height: initial;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap; }

.menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.homepage-offset .home-sidebar__content ul li a .chevron-icon {
  position: absolute;
  right: 2px;
  z-index: 100;
  width: 44px;
  padding-left: 15px;
  background: #22323f; }

.homepage-offset .home-sidebar__content ul li a.active .chevron-icon,
.homepage-offset .home-sidebar__content ul li:hover a .chevron-icon {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 44px;
  padding-left: 30px; }

.homepage-offset .home-sidebar__content ul li a.active .chevron-icon::after,
.homepage-offset .home-sidebar__content ul li:hover a .chevron-icon::after {
  display: none; }

.swiper-container {
  padding-top: 10px;
  padding-bottom: 20px; }

.ya-share2__link[title="Facebook"] {
  margin-right: -5px; }

.button-question.red {
  background: #22323f; }

.answer-box .button-question.red {
  background: #d17077; }

.swiper-scrollbar-drag {
  top: -1px !important; }

@media (max-width: 400px) {
  .slider-section .court-case__tags .court-case__tag {
    font-size: 12px;
    line-height: 12px; }
  .court-case__text {
    height: 6.5em; } }

.affairs-item__tag {
  margin-right: 10px; }

.overflow-h {
  overflow: hidden; }

.material:hover {
  transition: 1s;
  background-color: #d17077 !important; }
  .material:hover span {
    transition: 1s;
    color: #fff !important; }

.affairs-list .container-default .text-center {
  display: flex;
  justify-content: center; }

.homepage-jury-trial .carousel-wrapper .swiper-container {
  padding-left: 0; }

@media screen and (max-width: 575px) {
  .donate-block__regular-mobile {
    display: none; }
  .social {
    text-align: center; }
  .homepage-jury-trial__title h2 {
    font-size: 36px; }
  footer#footer .footer-top .right {
    padding-top: 33px; }
  #footer .contact-info {
    text-align: center; }
  .contact-info .social {
    display: flex;
    justify-content: center; }
  .more-posts {
    padding-bottom: 50px !important; }
  .button-next-slide,
  .button-prev-slide {
    background-color: #d17077 !important; }
  .button-next-slide svg path {
    stroke: #fff; }
  body .tox-tinymce {
    max-height: 97px !important; }
  .tox-toolbar {
    display: flex;
    justify-content: flex-end; }
  #homepage-jury-trial-scrollbar .swiper-scrollbar-drag,
  #homepage-mass-media-scrollbar .swiper-scrollbar-drag,
  #feedbacks-scrollbar .swiper-scrollbar-drag {
    top: -1px; }
  .mob_br {
    display: none; }
  .donate-block__regular .donate-block__regular__title {
    color: #dff0f4; }
  .homepage-mass-media .home-container {
    margin-bottom: 0; }
  .homepage-header__top {
    padding-bottom: 46px; }
  .homepage-header__numbers {
    margin-top: 44px; }
  .number-box__text {
    margin-left: 16px;
    max-width: 173px; }
  .input-range .line .dot__title {
    left: -12px; }
  #site-navigation {
    top: -1px; }
  .navigation-offset {
    padding-top: 70px; }
  .slider-section .court-case__tags {
    display: block; }
  .slider-section .court-case__tags .court-case__tag {
    font-size: 16px;
    line-height: 18px; }
  .court-case:last-child {
    margin-right: 15px; }
  .first-dot {
    left: 0 !important; }
  .last-dot {
    left: -33px !important; }
  .sidebar__content.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 87vh; }
  .sidebar__content.scrollable::-webkit-scrollbar {
    width: 1px; }
  .sidebar__content.scrollable::-webkit-scrollbar-track {
    background-color: #575757; }
  .sidebar__content.scrollable::-webkit-scrollbar-thumb {
    background: #f09d9a; }
  .sidebar__content {
    border-bottom: 0; }
  #homepage-jury-trial-scrollbar {
    width: 100%; }
  .more-posts__carousel-buttons p {
    margin-top: 28px;
    margin-bottom: -31px; }
  .homepage-mass-media {
    padding-bottom: 80px; }
  body .vote-section {
    background-position-y: 26.5%; } }

@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .container-default {
    width: 995px; }
  #site-navigation::before {
    width: 245px; }
  .show#site-navigation::before {
    width: 445px !important; }
  .homepage-header__numbers {
    top: -17%;
    margin-left: 0; }
  .homepage-quote .home-container {
    padding-bottom: 40px; }
  .homepage-quote__title {
    left: -16px; }
  .homepage-quote__quote {
    right: 60px; }
  .home-container {
    width: 680px; }
  .donate-block {
    height: 825px; }
  .donate-button {
    width: 460px; }
  .donate-button svg {
    margin-left: 50px; }
  .homepage-achievements__title .anim-text {
    width: 325px; }
  .donate-block__calculator-container {
    flex-direction: column; }
  .donate-block__title {
    font-size: 47px; }
  .donate-block__subtitle {
    width: 275px; }
  .donate-block__regular,
  .donate-block__form {
    width: 100% !important;
    margin: 0; }
  .button-red {
    padding-left: 77px;
    padding-right: 77px; }
  .donate-block__form {
    margin-bottom: 20px; }
  .donate-block__form__bottom {
    justify-content: space-between; }
  .donate-block__regular {
    display: flex;
    padding-bottom: 44px;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 40px; }
  .donate-block__regular a {
    flex: 0 0 40%; }
  .donate-block__regular__title {
    flex: 0 0 55%;
    line-height: 29px;
    margin-bottom: 0; }
  .sidebar__content {
    margin-left: 10px; }
  .affairs-item__title {
    font-size: 28px; }
  .vote-section .right {
    padding-top: 40px; }
  .sidebar__content .district:nth-child(3),
  .sidebar__content .district:nth-child(5) {
    padding-left: 25px; }
  .answer-box .circle {
    min-width: 35%; }
  .vote-section {
    height: 730px; } }

.detail-page {
  overflow-x: hidden; }

.filter-button span {
  padding-right: 10px; }

.comments-section .output {
  color: #d17077; }

.dropdown-button .trigger svg path {
  stroke: #d17077; }

.sidebar__content .sidebar__footer .left p {
  color: #dff0f4; }

#footer .contact-info a {
  color: #7c7c7c; }

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  z-index: 0; }

.tox .tox-editor-container {
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px; }

.mce-content-body p {
  line-height: 14px !important; }

.new-comment {
  line-height: 14px !important; }

.not-block {
  display: none; }

.input-range.light .line .dot__title {
  top: -60px; }

@media (max-width: 768px) {
  .donate-block .container-default {
    width: auto; }
  .donate-block__calculator-container-single-case-light {
    background-color: #f7f7fc; }
  .donate-block-light-blue {
    background-color: #f7f7fc !important; }
  .input-donate.light .placeholder {
    background-color: #f7f7fc; }
  .is-resized-img {
    width: 100%; }
    .is-resized-img img {
      width: 100%; }
  footer#footer .footer-top {
    padding-bottom: 0; }
  .home-container {
    width: 700px; } }

@media (max-width: 768px) {
  .donate-block {
    padding-bottom: 80px; }
  .court-header__row {
    display: block; } }

@media (min-width: 550px) and (max-width: 768px) {
  .donate-block__form__bottom {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    .donate-block__form__bottom .input-donate {
      margin-top: 65px;
      margin-bottom: 0; }
    .donate-block__form__bottom .donate-button {
      position: relative;
      top: -3px; }
  .donate-block__regular {
    display: flex;
    padding: 36px 48px 32px 40px; }
  .donate-block__regular__title {
    margin-bottom: 0;
    flex: 0 0 60.62%;
    font-size: 25px; }
    .donate-block__regular__title br {
      display: none; }
  .footer-menu-list:nth-child(1),
  .footer-menu-list:nth-child(3) {
    order: -1; } }

@media (max-width: 375px) {
  .h1 {
    font-size: 30px !important; }
  .homepage-jury-trial__title .h1 svg {
    margin-left: 20px; }
  .homepage-achievements__title.anime-block {
    min-height: 190px;
    height: auto; }
  .sidebar__content {
    top: 65px;
    height: 84.5vh; }
  .sidebar__footer {
    padding-bottom: 20px; }
  .donate-block {
    padding: 18px;
    padding-bottom: 66px; }
    .donate-block .home-container {
      width: 100%; }
  #site-navigation .dark {
    padding-left: 0; }
  .donate-block__form {
    padding-left: 25px;
    padding-right: 25px; }
  .homepage-quote__quote {
    width: 100%; }
  .homepage-achievements__title .anim-text {
    width: 100%; }
  .homepage-quote {
    padding-bottom: 72px; }
  .donate-button {
    padding-right: 0;
    font-size: 17px; }
  .vote-section .vote-choise .answer-box {
    align-items: flex-start;
    flex-direction: column; }
    .vote-section .vote-choise .answer-box button {
      width: 100% !important; }
  #site-navigation .dark img {
    margin: 0; } }

@media (max-width: 375px) and (max-height: 740px) {
  .homepage-header__top {
    padding-bottom: 20px; }
  .homepage-header__subtitle {
    margin-bottom: 25px; } }

@media (max-height: 570px) {
  .homepage-header__top {
    padding-bottom: 16px; }
  .h1 {
    font-size: 27px !important; }
  .homepage-header__title,
  .homepage-header__subtitle {
    margin-bottom: 15px; } }

.comments__social {
  display: flex; }

@media (min-width: 620px) and (max-height: 630px) {
  .sidebar__header {
    padding-top: 25px;
    padding-bottom: 20px; }
  .sidebar .close-sidebar {
    top: 24px; }
  .sidebar__content {
    top: 72px; }
  #site-navigation.show .logo {
    opacity: 0;
    visibility: hidden; }
  .sidebar__header {
    padding-left: 0; } }

@media (min-width: 992px) and (max-height: 630px) {
  .sidebar .close-sidebar {
    top: 0; } }

@media (max-width: 425px) {
  .homepage-jury-trial .f-c {
    margin-bottom: 50px; }
  .comments-list__top .h2 {
    margin-bottom: 20px; }
  .input-range.light .line .dot:nth-child(2) .dot__title {
    left: -9px; }
  .contact-info br {
    display: none; } }

@media (max-width: 374px) {
  .f-c.anime-block {
    min-height: 235px;
    height: auto; }
  .homepage-jury-trial .f-c {
    margin-bottom: 20px; } }

@media (min-width: 992px) {
  .popup-window .content.content-scrool {
    height: 467px !important; }
  .donate-block .container-default {
    display: flex;
    justify-content: center; } }

.b2 br {
  display: none; }

@media (max-width: 425px) {
  .input-range .line .dot__title {
    top: -52px; }
  .homepage-quote__quote,
  .homepage-achievements__title .anim-text {
    width: 100%; }
  .homepage-mass-media__carousel {
    padding-left: 15px; }
  .footer-bottom {
    justify-content: flex-end !important; }
  footer#footer .footer-bottom .container-default {
    width: 94.4%;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between; }
    footer#footer .footer-bottom .container-default a {
      width: 50%; }
    footer#footer .footer-bottom .container-default .made-by {
      margin-top: 30px;
      order: 2;
      text-align: center;
      width: 100%; }
    footer#footer .footer-bottom .container-default .develop-by {
      display: flex;
      align-items: flex-end; }
  .donate-block {
    padding-bottom: 80px; }
  .button-pink:hover {
    color: #22323f;
    background: #f09d9a; }
  .donate-block__regular__title {
    margin-right: 0; }
    .donate-block__regular__title br:nth-child(1) {
      display: block; }
  .feedback-box__link {
    margin-top: 10px;
    margin-left: 0; } }

@media (max-width: 375px) {
  .donate-block__regular {
    padding-left: 25px;
    padding-right: 25px; }
  .donate-block__regular__title {
    font-size: 21px; }
  .donate-block {
    padding: 0 15px 40px; } }

iframe {
  width: 100%; }

.protocols-btn-group {
  text-align: center; }

a.bt-popup:hover {
  color: #c7575e; }

@media (min-width: 1024px) and (max-width: 1024px) {
  .homepage-header__numbers {
    padding: 13px;
    padding-bottom: 32px;
    margin-left: 15px;
    width: 96% !important; }
  .home-container {
    margin-left: 15px; }
  .homepage-achievements__title .anim-text {
    width: 370px; }
  .achievement-box,
  .homepage-achievements__title {
    width: 48% !important; }
  .home-container {
    width: 830px; }
  .donate-block {
    height: 800px; }
  .donate-block .home-container {
    width: 100%;
    height: 100%; }
  .donate-block__calculator-container {
    flex-wrap: wrap;
    padding-right: 20px; }
  .donate-block__form {
    margin-bottom: 20px;
    width: 100% !important; }
  .donate-block .home-container .donate-block__calculator-container .donate-block__regular {
    padding-bottom: 24px;
    width: 100%;
    margin: 0; }
  .donate-block__regular__title {
    margin-bottom: 35px; } }

@media (min-width: 1240px) and (max-width: 1290px) {
  .homepage-header__numbers {
    margin-left: 20px;
    width: 96% !important; }
  .homepage-quote__quote {
    right: 90px; }
  .home-container {
    width: 900px; }
  .donate-block .home-container .donate-block__calculator-container .donate-block__form {
    width: 625px; } }

@media (max-width: 550px) {
  .home-container {
    width: 100%; }
  .homepage-jury-trial .carousel-wrapper .swiper-container {
    margin-left: 0; }
  .more-posts .carousel-wrapper {
    max-width: unset; } }

.show-more {
  color: #d17077;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 600; }
  .show-more:hover {
    color: #c34b53; }

.swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
  transition: 1s;
  transform: rotate(-30deg) translateX(-600px) !important; }

.members-association__swiper-container {
  overflow: unset;
  position: relative; }
  .members-association__swiper-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: -115vw;
    background-color: #fff;
    height: 100%;
    z-index: 10;
    width: 100vw; }

.association {
  overflow: hidden; }
  .association__title {
    font-size: 50px;
    font-weight: 700;
    font-family: "Gilroy";
    line-height: 33px; }
  .association-card__img {
    width: 100%;
    height: 275px; }
    .association-card__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .association-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 231px;
    border: 1px solid #bebebe;
    border-top: none;
    box-sizing: border-box;
    padding: 24px 16px 31px 16px; }
  .association-card__subtitle {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    overflow: hidden;
    height: 48px; }
    .association-card__subtitle._open {
      overflow: visible;
      max-height: unset;
      height: unset; }
  .association-card__text {
    max-height: 72px;
    margin-bottom: 16px;
    overflow: hidden; }
    .association-card__text span {
      font-weight: 700;
      margin-bottom: 20px; }
    .association-card__text._open {
      overflow: visible;
      max-height: unset; }
  .association-pagination {
    display: flex;
    align-items: center; }

.swiper-button-pink {
  flex: 0 0 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d17077;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 40px;
  opacity: 1;
  transition: 0.3s;
  margin-left: 8px; }

.swiper-button-pink:hover {
  background-color: #d17077; }
  .swiper-button-pink:hover svg path {
    stroke: #fff; }

.association__slider-header {
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

.slider-btns {
  display: flex;
  width: 170px; }

.swiper-button-disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5; }

.swiper-pagination-current,
.swiper-pagination-total {
  padding: 0 5px; }

.council-members__swiper-container {
  overflow: unset; }

.chairperson {
  margin-bottom: 95px; }
  .chairperson__title {
    margin-bottom: 37px; }
  .chairperson__block {
    display: flex; }
  .chairperson__img {
    flex: 0 0 380px;
    height: 373px; }
    .chairperson__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .chairperson__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 27px 32px 40px;
    border: 1px solid #bebebe; }
  .chairperson__subtitle {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700; }
  .chairperson__text {
    max-height: 190px;
    margin-bottom: 16px;
    overflow: hidden; }
    .chairperson__text._open {
      overflow: visible;
      flex: 0 1 100%;
      max-height: unset; }

.council-members {
  height: 100%;
  padding-bottom: 80px;
  padding-top: 81px;
  background: #f7f7fc; }

.members-association {
  padding-top: 87px;
  padding-bottom: 145px; }
  .members-association .association-card {
    height: 508px; }
    .members-association .association-card__img {
      height: 275px; }
    .members-association .association-card__content {
      flex: 0 0 232px;
      padding: 24px 13px 32px 17px; }

.join-us {
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f09d9a; }
  .join-us__link {
    font-size: 50px;
    color: #000000;
    font-family: "Gilroy";
    transition: 0.5s;
    font-weight: 400; }
    .join-us__link span {
      margin-right: 47px;
      font-weight: 500; }
    .join-us__link span:hover {
      color: #000; }
    .join-us__link:hover {
      color: #000;
      font-size: 51px; }

.association-card._open {
  height: 100%; }

.join-us__link {
  transition: 1s; }

.join-us__link:hover {
  transform: scale(1.1);
  font-size: 50px; }

@media (max-width: 1024px) {
  .chairperson__block {
    width: 100%; }
  .chairperson__img {
    flex: 0 0 45%; }
  .chairperson__content {
    flex: 0 0 55%; }
  .association-card__img {
    height: 290px; }
  .association .swiper-container {
    left: 0; } }

@media (max-width: 768px) {
  .swiper-container {
    padding-bottom: 0; }
  .association .container-default {
    padding: 0 34px; }
  .join-us__link {
    font-size: 40px; }
    .join-us__link span {
      margin-right: 40px; }
    .join-us__link:hover {
      font-size: 42px; }
  .members-association {
    padding-bottom: 34px; } }

@media (max-width: 425px) {
  .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
    transition: 1s;
    opacity: 1;
    transform: none !important;
    visibility: visible; }
  .join-us__link {
    transition: 1s; }
  .join-us__link:hover {
    transform: scale(1); }
  .council-members {
    padding-top: 56px; }
  .association .container-default {
    padding: 0; }
  .chairperson__block {
    flex-direction: column; }
  .chairperson__img {
    flex: 0 0 317px; }
  .chairperson__content {
    padding: 32px 24px;
    border-left: 1px solid #bebebe;
    border-top: none; }
  .chairperson__text {
    max-height: 96px; }
  .association__title {
    font-size: 35px;
    margin-bottom: 32px !important; }
  .association-card {
    height: auto !important;
    max-width: 280px;
    margin: auto; }
    .association-card-wrapper {
      margin-bottom: 20px; }
  .association__slider-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 7px; }
    .association__slider-header .swiper-button-pink {
      display: none; }
  .join-us {
    height: 244px; }
    .join-us__link {
      font-size: 35px; }
      .join-us__link span {
        margin-right: 25px; }
      .join-us__link svg {
        width: 27px;
        height: 27px; }
      .join-us__link:hover {
        font-size: 35px; }
  .members-association {
    padding-bottom: 82px;
    padding-top: 56px; }
  .council-members {
    padding-bottom: 65px; }
  .association__title {
    margin-bottom: 0; } }

@media (max-width: 350px) {
  .join-us__link {
    font-size: 33px; }
    .join-us__link svg {
      width: 25px;
      height: 25px; }
    .join-us__link span {
      margin-right: 15px; }
    .join-us__link:hover {
      font-size: 33px; } }

.kama_breadcrumbs {
  padding-top: 17px;
  margin-bottom: 40px; }

.kama_breadcrumbs a {
  color: #22323f; }

.kama_breadcrumbs .kb_title {
  color: #b4b2b2; }

.association-card-wrapper {
  margin-bottom: 20px; }

ul,
ol,
li {
  list-style: none; }

.step-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px; }

.header-step {
  max-width: 673px;
  margin: 56px auto; }
  .header-step__list {
    display: flex;
    justify-content: space-between;
    position: relative; }
    .header-step__list::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      background: #22323f;
      height: 2px;
      width: 100%;
      z-index: -1; }
  .header-step__item {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    padding-top: 3px;
    text-align: center;
    font-weight: 700;
    line-height: 40px;
    color: #22323f;
    font-size: 18px;
    background: #fff;
    border: 2px solid #22323f; }
    .header-step__item_active {
      border-color: #22323f; }
    .header-step__item_color {
      background-color: #22323f;
      color: #fff; }

.step-number {
  display: flex; }

.step-form {
  padding-bottom: 100px;
  color: #22323f;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.07em; }

.step-column-left {
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  flex: 0 0 66%;
  padding: 37px 80px 60px;
  border: 1px solid rgba(34, 50, 63, 0.3);
  border-radius: 28px; }
  .step-column-left__title {
    padding-right: 19%;
    line-height: 30px;
    font-size: 30px; }
  .step-column-left__text {
    width: 94%;
    margin-top: 20px; }

.step-column-right-block {
  padding: 30px 24px 40px;
  border: 1px solid rgba(34, 50, 63, 0.3);
  border-radius: 28px; }
  .step-column-right-block:not(:last-child) {
    margin-bottom: 16px; }
  .step-column-right-block__paragraph:not(:last-child) {
    margin-bottom: 25px; }

.step__textarea {
  resize: none;
  width: 100%;
  margin-top: 29px;
  padding: 20px 17px;
  color: #22323f;
  background: #f5f5f5;
  border-radius: 19px;
  border: none; }
  .step__textarea-big {
    min-height: 154px; }
  .step__textarea-small {
    min-height: 97px;
    margin-bottom: 38px; }
  .step__textarea-counter {
    position: absolute;
    bottom: 23px;
    right: 23px; }

.step__buttons {
  display: flex;
  justify-content: space-between; }
  .step__buttons-three {
    margin-top: 20px;
    width: 100%; }

.step-number {
  display: none; }
  .step-number.step_active {
    display: flex; }

.step__btn-further {
  padding: 16px 94px; }

.step__btn-1step {
  margin-left: auto; }

.step__btn-save {
  margin-left: 10px;
  display: flex;
  padding: 15px 10px;
  justify-content: center;
  flex: 0 1 395px; }

.step-three-number {
  flex-direction: column;
  padding: 37px 80px 60px;
  border: 1px solid rgba(34, 50, 63, 0.3);
  border-radius: 28px; }

.step-three-wrapper {
  display: flex; }

.step-three-column-left {
  flex: 0 0 65%; }

.step-three-column-right {
  flex: 0 0 30%; }
  .step-three-column-right img {
    width: 100%; }

.step-three__title {
  margin-bottom: 49px; }

.step-three__paragraph {
  width: 95%;
  margin-bottom: 25px; }

.paragraph__subtitle {
  width: 95%;
  font-weight: 700; }

.counter-wrapper {
  position: relative;
  margin-bottom: 34px; }

.step-editor {
  background: #f5f5f5;
  margin-top: 34px;
  border: none;
  border-radius: 19px;
  overflow: hidden; }

.step-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }

.step__btn-prev {
  border: none;
  background: none;
  color: #d17077; }

.step-text-point {
  background: url(../img/step-icon-point.png) no-repeat;
  padding-left: 40px;
  margin-bottom: 32px; }

.step-file {
  display: flex;
  align-items: center;
  position: relative;
  text-align: start;
  color: #d17077; }
  .step-file::before, .step-file::after {
    display: inline-block;
    vertical-align: middle;
    vertical-align: text-bottom;
    height: 16px; }
  .step-file::before {
    content: url(../img/file-icon.svg);
    margin-right: 12px; }
  .step-file-btn-close {
    width: 16px;
    height: 16px;
    border: none;
    background: url(../img/delete-file.svg) no-repeat;
    margin-left: 12px;
    cursor: pointer; }

.step-input__wrapper {
  width: 240px;
  margin-right: 24px;
  position: relative;
  text-align: center; }

.step-input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute; }
  .step-input__file-button {
    position: relative;
    max-width: 240px;
    height: 49px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    cursor: pointer;
    justify-content: center;
    border: 1px solid #22323f;
    box-sizing: border-box;
    border-radius: 47px; }

.step-link {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border: none; }
  .step-link-wrapper {
    display: flex;
    flex-grow: 1;
    height: 49px;
    padding: 10px 22px;
    background: #f5f5f5;
    border-radius: 47px; }

.step-input__file-button::after, .step-link-wrapper::after {
  content: url(../img/step-question.png);
  padding-left: 10px;
  padding-top: 5px; }

@media (max-width: 992px) {
  .step-container {
    max-width: 720px; }
  .step__textarea {
    padding: 17px 21px; }
  .step-number {
    flex-direction: column; }
  .step-three-column-left {
    flex: 0 0 55%; }
  .step-three-column-right {
    flex: 0 0 45%;
    align-self: center; }
  .step-column-left {
    padding-left: 7.5%;
    padding-right: 5.5%;
    margin-bottom: 24px;
    margin-right: 0; }
  .step-column-right-block {
    padding-left: 8%;
    padding-right: 20%; }
  .counter-wrapper {
    margin-bottom: 36px; } }

@media (max-width: 768px) {
  .step-column-left {
    padding-left: 5.5%; }
  .step-column-right-block {
    padding-top: 37px;
    padding-left: 5.7%;
    padding-right: 17%; } }

@media (max-width: 670px) {
  .step-three-wrapper {
    flex-direction: column; }
  .step__buttons-three {
    align-items: center;
    flex-direction: column-reverse; }
  .step__btn-save {
    flex: 0 0 57px;
    width: 75%;
    margin-bottom: 32px; } }

@media (max-width: 475px) {
  .step__buttons,
  .step-data {
    flex-direction: column-reverse; }
    .step__buttons > *,
    .step-data > * {
      width: 100%;
      margin-top: 32px; }
  .step-input__file-button {
    max-width: 100%;
    width: 100%; } }

@media (max-width: 375px) {
  .header-step {
    margin: 58px 0; }
  .step-container {
    padding: 0 17px; }
  .step-column-left {
    padding-top: 43px;
    padding-left: 6.5%;
    padding-bottom: 53px; }
    .step-column-left__title {
      line-height: 33px; }
    .step-column-left__text {
      margin-top: 25px; }
  .step-column-right-block {
    padding-right: 10%; }
  .step__textarea {
    margin-top: 31px;
    padding: 20px 17px; }
    .step__textarea-big {
      min-height: 146px; }
    .step__textarea-small {
      min-height: 30px;
      margin-bottom: 33px;
      padding: 20px 19px 0; }
  .step-three-number {
    padding: 40px 24px 50px 24px; }
  .step__btn-1step {
    margin-left: 0; }
  .step__btn-save {
    width: 100%; } }

.page-petition {
  display: flex;
  align-items: flex-start;
  padding-top: 55px;
  padding-bottom: 72px;
  justify-content: space-between; }
  .page-petition-content {
    flex: 0 0 64%;
    margin-right: 5%; }
  .page-petition__title {
    margin-bottom: 32px;
    font-size: 50px;
    line-height: 113.49%; }
  .page-petition-info {
    margin-bottom: 40px; }
    .page-petition-info__social {
      margin-left: 5px; }
    .page-petition-info-author {
      margin-right: 7px; }
    .page-petition-info > * {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600; }
  .page-petition-imgs {
    margin-bottom: 29px; }
  .page-petition-img {
    width: 100%;
    margin-bottom: 16px; }
    .page-petition-img img {
      width: 100%; }
  .page-petition-text {
    margin-bottom: 24px;
    font-size: 18px; }
  .page-petition-button {
    display: block;
    width: 100%;
    height: 48px;
    padding: 10px;
    color: #d17077;
    font-weight: 600;
    background-color: #fff;
    border: 2px solid #d17077;
    box-sizing: border-box;
    border-radius: 44px; }
    .page-petition-button-wrapper {
      width: 246px; }
    .page-petition-button__instruction {
      margin-bottom: 8px; }
    .page-petition-button__bg-pink {
      margin-bottom: 16px;
      background: #d17077;
      color: #fff; }

.color-pink {
  color: #D17077; }

.interaction {
  margin-bottom: 16px;
  padding: 14px 24px 56px; }
  .interaction-checkbox__list {
    margin-bottom: 40px; }
  .interaction-checkbox__item {
    margin-bottom: 20px; }
    .interaction-checkbox__item label {
      margin-bottom: 0; }
  .interaction-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .interaction-checkbox + label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none; }
  .interaction-checkbox + label::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #d17077;
    border-radius: 2px;
    margin-top: 5px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }
  .interaction-checkbox:checked + label::before {
    border-color: #d17077;
    background-color: #d17077;
    background-image: url(../img/step-checkbox.svg); }
  .interaction-social {
    margin-bottom: 16px;
    height: 112px;
    padding: 19px 10px 29px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .interaction-social__list {
      display: flex;
      justify-content: space-between;
      width: 154px; }
  .interaction__disabled-btn {
    pointer-events: none;
    opacity: 0.5; }
  .interaction .author__btn {
    border: 1px solid #d17077; }
  .interaction .author-pub__text {
    width: 81%;
    margin-bottom: 41px;
    font-size: 20px;
    line-height: 24px; }
    .interaction .author-pub__text span {
      font-weight: 700; }
  .interaction .author-pub-mailing {
    padding: 28px 16px 27px;
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .interaction .author-pub-mailing__title {
      margin-bottom: 18px;
      font-size: 20px; }
    .interaction .author-pub-mailing__price {
      margin-bottom: 18px;
      text-align: center; }
      .interaction .author-pub-mailing__price span {
        font-size: 20px;
        font-weight: 700; }
    .interaction .author-pub-mailing__detail {
      margin: 0 auto;
      display: block;
      border: none;
      color: #d17077;
      background-color: transparent;
      font-weight: 700; }
      .interaction .author-pub-mailing__detail::after {
        content: url(../img/step-detail-arrow.svg);
        padding-left: 5px;
        vertical-align: text-top; }
  .interaction .author-edit-mailing {
    margin-bottom: 16px;
    padding: 28px 24px 40px; }
    .interaction .author-edit-mailing__title {
      margin-bottom: 24px; }
    .interaction .author-edit-mailing__row {
      margin-bottom: 35px;
      display: flex;
      width: 100%;
      justify-content: space-between; }
    .interaction .author-edit-mailing__detail {
      display: block;
      border: none;
      color: #d17077;
      background-color: transparent;
      font-weight: 700; }
  .interaction .user-petition {
    margin-bottom: 16px;
    padding: 50px 24px; }
    .interaction .user-petition__row {
      display: flex;
      align-items: center;
      margin-bottom: 33px; }
    .interaction .user-petition__quantity {
      display: flex;
      align-items: center;
      margin-right: 60px; }
      .interaction .user-petition__quantity img {
        margin-right: 12px; }
    .interaction .user-petition__form {
      display: none; }
      .interaction .user-petition__form._open {
        display: block; }
    .interaction .user-petition__input {
      width: 100%;
      height: 55px;
      padding-left: 24px;
      border: 1px solid #7C7C7C;
      box-sizing: border-box;
      border-radius: 11px; }
    .interaction .user-petition__input:nth-child(1) {
      margin-bottom: 16px; }
    .interaction .user-petition__input:nth-child(2) {
      margin-bottom: 24px; }
    .interaction .user-petition .sign-petition._close {
      display: none; }
  .interaction .admin {
    border-radius: 19px; }
    .interaction .admin__info {
      margin-bottom: 16px;
      padding: 36px 24px 40px; }
    .interaction .admin__title {
      margin-bottom: 19px;
      font-size: 20px; }
    .interaction .admin__text {
      margin-bottom: 32px; }
    .interaction .admin__file:not(:last-child) {
      margin-bottom: 8px; }
  .interaction .user-connection {
    display: block;
    padding: 15px 0;
    text-align: center;
    font-weight: 600;
    color: #22323F;
    border-radius: 17px; }

.wrapper-border {
  border: 1px solid #BEBEBE;
  box-sizing: border-box;
  border-radius: 24px; }

.scale {
  position: relative;
  width: 100%;
  height: 5px;
  margin-bottom: 40px;
  background: #dfdfdf;
  border-radius: 19px; }
  .scale::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 24%;
    height: 5px;
    background: #d17077;
    border-radius: 19px; }

.number-people,
.objective {
  font-weight: 700; }

@media (max-width: 992px) {
  .page-petition-column-left {
    flex: 0 0 57%; } }

@media (max-width: 768px) {
  .page-petition {
    flex-direction: column-reverse; }
    .page-petition-column-left {
      flex: 0 0 57%; }
    .page-petition-column-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 56px; }
      .page-petition-column-right button {
        width: 48%; }
    .page-petition-column-right__text {
      padding-top: 35px;
      width: 45%;
      order: -2; }
    .page-petition-mailing {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 54%;
      order: -1; }
      .page-petition-mailing__detail {
        margin: 0; }
    .page-petition-checkbox__list {
      display: flex; }
    .page-petition-button__bg-pink {
      order: 5; } }

@media (max-width: 575px) {
  .page-petition-column-right {
    flex-direction: column; }
    .page-petition-column-right__text {
      width: 100%; }
    .page-petition-column-right button {
      width: 100%; }
  .page-petition-mailing {
    width: 100%;
    flex-direction: column; }
  .page-petition-button__bg-pink {
    order: 0; }
  .page-petition-checkbox__list {
    flex-direction: column; }
  .page-petition__title {
    font-size: 30px; }
  .page-petition-text {
    font-size: 16px; } }

.hystmodal.hystmodal--active {
  backdrop-filter: blur(7px); }

.hystmodal__wrap {
  padding: 0 17px; }

.hystmodal__shadow.hystmodal__shadow--show {
  opacity: 0.3; }

.hystmodal__window {
  width: 450px;
  padding: 50px 61px 64px 60px; }

.modal__disabled-btn {
  pointer-events: none;
  opacity: 0.5; }

.hystmodal__close {
  top: 25px;
  right: 25px;
  width: 31px;
  height: 31px;
  background-image: url(../img/modal-close.svg); }

.modal__link {
  text-decoration: underline;
  color: #d17077; }

.modal__input {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  height: 55px;
  border: none; }
  .modal__input:last-child {
    margin-bottom: 32px; }
  .modal__input.vk {
    background-image: url(../img/icon-vk.svg);
    background-repeat: no-repeat;
    background-position: 95%; }
  .modal__input.facebook {
    background-image: url(../img/icon-facebook.svg);
    background-repeat: no-repeat;
    background-position: 95%; }
  .modal__input.inst {
    background-image: url(../img/icon-inst.svg);
    background-repeat: no-repeat;
    background-position: 95%; }
  .modal__input.odnoklas {
    background-image: url(../img/icon-odnoklas.svg);
    background-repeat: no-repeat;
    background-position: 95%; }

.modal-registration__text {
  margin-right: 4px; }

.modal-registration__row {
  margin-bottom: 40px;
  display: flex; }
  .modal-registration__row-soc {
    display: flex;
    justify-content: center; }

.modal-reg-soc__link {
  display: flex;
  justify-content: center; }

.modal-sms__title {
  margin-bottom: 16px;
  font-size: 30px; }

.modal-sms__text {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px; }
  .modal-sms__text-soc {
    margin-bottom: 16px;
    display: flex;
    justify-content: center; }

.modal-sms_bold {
  margin-left: 5px;
  font-weight: 700; }

.modal-sms__row {
  margin-bottom: 48px; }
  .modal-sms__row-soc {
    display: flex;
    justify-content: center; }

.modal-sms__input {
  margin-right: 44px;
  width: 90px;
  height: 40px;
  border: 1.5px solid #D17077;
  box-sizing: border-box;
  border-radius: 11px; }

.modal-sms__text-repeat {
  margin-bottom: 17px; }

.modal-sms__counter {
  font-weight: 600; }

.modal-sms-btn_gray {
  margin-bottom: 40px;
  color: #fff;
  background: #BEBEBE;
  border-color: #BEBEBE; }

.modal-sms__link {
  margin-bottom: 40px;
  display: flex;
  justify-content: center; }

.modal-sms__soc {
  width: 96px;
  height: 56px;
  background: #F3F3F3; }
  .modal-sms__soc:not(:last-child) {
    margin-right: 11px; }

.modal-sms__vk {
  background: url(../img/icon-vk.svg) no-repeat;
  background-position: 50% 50%; }

.modal-sms__facebook {
  background: url(../img/icon-facebook.svg) no-repeat;
  background-position: 50% 50%; }

.modal-sms__google {
  background: url(../img/icon-google.svg) no-repeat;
  background-position: 50% 50%; }

.modal-mailing {
  width: 780px;
  padding: 75px 81px 37px; }
  .modal-mailing__content {
    max-width: 400px;
    margin: 0 auto; }
  .modal-mailing__title {
    margin-bottom: 52px;
    font-size: 30px;
    line-height: 36px;
    text-align: center; }
  .modal-mailing__links {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap; }
  .modal-mailing .link-soc {
    margin-right: 6%; }
  .modal-mailing__link {
    display: flex;
    margin: 0 10px;
    align-items: center;
    height: 54px;
    padding-left: calc(8% - 17px);
    flex: 0 0 calc(50% - 20px);
    border: 1px solid #7C7C7C;
    box-sizing: border-box;
    border-radius: 6px; }
    .modal-mailing__link:last-child {
      flex: 0 0 calc(100% - 20px);
      padding-left: 0;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 40px; }
    .modal-mailing__link:not(:last-child) {
      margin-bottom: 16px; }
  .modal-mailing__later {
    display: flex;
    justify-content: center;
    text-decoration: underline; }

.write-author {
  width: 800px;
  padding: 88px 60px 50px; }
  .write-author__content {
    display: flex; }
  .write-author__column-left {
    margin-right: 7%;
    flex: 0 0 41%; }
  .write-author__column-right {
    flex: 0 0 52%; }
  .write-author__icon {
    margin-bottom: 26px; }
  .write-author__title {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 36px;
    text-align: start; }
  .write-author__input {
    margin-bottom: 10px;
    width: 100%;
    height: 49px;
    padding-left: 24px;
    vertical-align: middle;
    border: none; }
  .write-author__textarea {
    padding: 14px 25px;
    margin-bottom: 27px;
    width: 100%;
    height: 102px;
    resize: none;
    line-height: 21px;
    border: none; }
  .write-author__wrapper {
    padding-right: 50px; }

@media (max-width: 768px) {
  .write-author {
    padding: 88px 40px 50px; } }

@media (max-width: 500px) {
  .modal-mailing__link {
    flex: 0 0 100%;
    padding-left: 20px; }
    .modal-mailing__link:last-child {
      margin-top: 21px;
      margin-bottom: 47px;
      flex: 0 0 100%;
      border: none; }
  .write-author__content {
    flex-direction: column; } }

@media (max-width: 375px) {
  .hystmodal__window {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 83px 21px 56px; }
  .modal-sms__text {
    flex-direction: column;
    align-items: center; }
  .write-author__wrapper {
    padding-right: 0; } }

.homepage-jury-trial__carousel-buttons p {
  display: none; }

.warning {
  position: fixed;
  top: 60px;
  right: 130px;
  width: 435px;
  padding: 30px 15px 31px 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  border-radius: 14px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s; }
  .warning._open {
    top: 100px;
    visibility: visible;
    opacity: 1; }
  .warning__icon {
    flex: 0 0 31px;
    height: 31px;
    background: url(../img/step-icon-point.png) no-repeat; }
  .warning__text {
    margin: 0 17px; }
  .warning__close {
    flex: 0 0 31px;
    height: 31px;
    background: url(../img/modal-close.svg) no-repeat;
    cursor: pointer; }

.petitions {
  padding-bottom: 250px; }
  .petitions-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 564px;
    padding-top: 115px;
    background: url(../img/petitions-map.svg) no-repeat;
    background-size: cover;
    background-position: center center; }
    .petitions-hero__title {
      margin-bottom: 50px;
      font-size: 50px; }
    .petitions-hero__btn {
      margin-bottom: 105px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 310px;
      height: 64px;
      font-size: 18px;
      text-transform: uppercase; }
    .petitions-hero__cards {
      position: relative;
      margin-left: 0;
      left: calc(50% - 503px);
      top: 30px; }
    .petitions-hero .number-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 16px; }
      .petitions-hero .number-box__number {
        margin-bottom: 0; }
      .petitions-hero .number-box__text {
        width: 71.5%; }
  .petitions-block {
    padding-top: 120px; }
    .petitions-block__title {
      margin-bottom: 40px;
      font-size: 50px; }
    .petitions-block__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px; }
    .petitions-block__search {
      position: relative;
      width: 355px;
      height: 55px;
      padding: 0 15px;
      border-radius: 11px; }
      .petitions-block__search input {
        width: 100%;
        height: 100%;
        border: none; }
      .petitions-block__search::after {
        content: url(../img/search-icon.svg);
        position: absolute;
        left: 96%;
        top: 40%; }
    .petitions-block__sorting span {
      position: relative;
      padding-right: 17px;
      color: #D17077; }
      .petitions-block__sorting span div {
        position: absolute;
        right: 0px;
        top: 7px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #D17077; }
    .petitions-block__list {
      display: flex;
      flex-wrap: wrap; }
    .petitions-block__item {
      display: flex;
      max-width: 580px;
      margin-bottom: 20px; }
      .petitions-block__item:nth-child(2n - 1) {
        margin-right: 20px; }
      .petitions-block__item-img {
        flex: 1 0 251px;
        height: 100%; }
        .petitions-block__item-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .petitions-block__item .content {
        padding: 24px 32px 20px 24px;
        border: 1px solid #BEBEBE;
        border-left: none;
        box-sizing: border-box; }
        .petitions-block__item .content__author {
          margin-bottom: 24px;
          font-size: 14px;
          color: #757575; }
        .petitions-block__item .content__title {
          margin-bottom: 41px;
          font-size: 20px; }
        .petitions-block__item .content__row {
          display: flex;
          margin-bottom: 11px; }
          .petitions-block__item .content__row:last-child {
            margin-bottom: 27px; }
          .petitions-block__item .content__row-icon {
            margin-right: 10px; }

@media (max-width: 1200px) {
  .petitions {
    padding-bottom: 200px; }
    .petitions-block__item {
      max-width: 500px; } }

@media (max-width: 992px) {
  .petitions {
    padding-bottom: 150px; }
    .petitions-hero__btn {
      margin-bottom: 80px; }
    .petitions-hero__cards {
      top: 30px !important;
      z-index: 10; }
      .petitions-hero__cards .number-box {
        flex-direction: row;
        justify-content: start;
        align-items: center; }
        .petitions-hero__cards .number-box__number {
          margin-right: 0; }
    .petitions-block__item {
      min-width: 100%; }
      .petitions-block__item .content {
        padding-right: 15%; } }

@media (max-width: 767px) {
  .petitions-hero__cards {
    left: 50% !important;
    transform: translate(-50%, 0%); } }

@media (max-width: 575px) {
  .petitions-block__item {
    flex-direction: column; }
    .petitions-block__item .content {
      border-left: 1px solid #BEBEBE; }
  .petitions-block__header {
    flex-direction: column;
    align-items: flex-start; }
  .petitions-block__search {
    width: 100%; } }
